import * as S from "./styles";
import {
  ICardsData,
  ICreditCard,
  TRecurrence,
  TBillingOption,
  ICreditCardHolderInfo,
  IProjectsOrder,
} from "../../../../types/order";
import {
  paymentMethod,
  paymentMethodValues,
} from "../../../../constants/order";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../constants/colors";
import { IUser } from "../../../../types";
import { irRate } from "../../../../constants/taxes";
import Button from "../../../../components/atoms/Button";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import OptionsList from "../../../../components/molecules/OptionsList";
import CreditCardInfo from "../../../../components/organisms/CreditCardInfo";

interface IProps {
  user: IUser;
  plan: TRecurrence;
  totalValue: number;
  addNewCard: boolean;
  selectedPayment: number;
  newCardInfo: ICardsData;
  onBuyHandler: () => void;
  onAddNewCard: () => void;
  onDiscardNewCard: () => void;
  selectedProject: IProjectsOrder;
  errors: { [key: string]: string[] };
  onSelectPayment: (val: TBillingOption) => void;
  onChangeCardInfo: (key: keyof ICreditCard, val: string) => void;
  onChangeCardHolderInfo: (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => void;
}

const CompleteBuy: React.FC<IProps> = ({
  plan,
  user,
  errors,
  totalValue,
  addNewCard,
  newCardInfo,
  onBuyHandler,
  onAddNewCard,
  selectedPayment,
  onSelectPayment,
  selectedProject,
  onDiscardNewCard,
  onChangeCardInfo,
  onChangeCardHolderInfo,
}) => {
  const projectWidth = "50%";
  const tCo2Width = "25%";
  const pricingWidth = "25%";

  const paymentOpt =
    plan === "annual" ? ["Cartão de crédito"] : paymentMethodValues;

  const disableButton =
    !selectedProject ||
    selectedPayment < 0 ||
    (Object.keys(paymentMethod)[selectedPayment] === "CREDIT_CARD" &&
      !addNewCard);

  const billingValue = user.simplesNacional
    ? totalValue
    : totalValue * (1 - irRate / 100);

  return (
    <S.CompleteBuy>
      <H1 color={textColor}>Resumo do Pedido</H1>

      <S.Content>
        <S.HeaderContent>
          <S.TitleCustom
            fontWeight="bold"
            color={textColor}
            width={projectWidth}
          >
            Projeto
          </S.TitleCustom>

          <S.TitleCustom width={tCo2Width} color={textColor} fontWeight="bold">
            tCO<sub>2</sub>
          </S.TitleCustom>

          <S.TitleCustom
            color={textColor}
            fontWeight="bold"
            width={pricingWidth}
          >
            Preço
          </S.TitleCustom>
        </S.HeaderContent>

        <S.ContentList>
          <S.Project>
            <S.TitleCustom width={projectWidth} color={textColor}>
              {selectedProject.name}
            </S.TitleCustom>

            <S.TitleCustom width={tCo2Width} color={textColor}>
              {selectedProject.quantity.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
              })}
            </S.TitleCustom>

            <S.TitleCustom width={pricingWidth} color={textColor}>
              R${" "}
              {selectedProject.pricing.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </S.TitleCustom>
          </S.Project>

          <S.TotalContainer>
            <S.Total>
              <S.TotalValue
                color={textColor}
                fontWeight="bold"
                width={projectWidth + tCo2Width}
              >
                Total
              </S.TotalValue>

              <S.TotalValue width={pricingWidth} color={textColor}>
                R${" "}
                {totalValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </S.TotalValue>
            </S.Total>

            {!user.simplesNacional && (
              <>
                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    width={projectWidth + tCo2Width}
                  >
                    Impostos retidos na fonte
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    - R${" "}
                    {(totalValue - billingValue).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </S.TotalValue>
                </S.Total>

                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    fontWeight="bold"
                    width={projectWidth + tCo2Width}
                  >
                    Valor da cobrança
                  </S.TotalValue>

                  <S.TotalValue
                    fontWeight="bold"
                    color={textColor}
                    width={pricingWidth}
                  >
                    R${" "}
                    {billingValue.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </S.TotalValue>
                </S.Total>
              </>
            )}
          </S.TotalContainer>
        </S.ContentList>

        <S.Payment>
          <H3 color={textColor} fontWeight="bold">
            Pagamento
          </H3>

          <OptionsList
            direction="column"
            options={paymentOpt}
            selectedItem={selectedPayment}
            label="Selecione o método de pagamento desejado:"
            setSelectedItem={(val) =>
              onSelectPayment(paymentOpt[val] as TBillingOption)
            }
          />
        </S.Payment>

        {paymentOpt[selectedPayment] === "Cartão de crédito" && addNewCard && (
          <CreditCardInfo
            errors={errors}
            onDiscard={() => onDiscardNewCard()}
            cardInfo={newCardInfo["creditCard"]}
            cardHolderInfo={newCardInfo["creditCardHolderInfo"]}
            onChangeCardInfo={(key, val) => onChangeCardInfo(key, val)}
            onChangeCardHolderInfo={(key, val) =>
              onChangeCardHolderInfo(key, val)
            }
          />
        )}

        {paymentOpt[selectedPayment] === "Cartão de crédito" && !addNewCard && (
          <S.Cards>
            <S.AddCardButton>
              <Button
                variant="dashed"
                textColor={primaryColor}
                borderColor={primaryColor}
                onClick={() => onAddNewCard()}
              >
                Adicionar novo cartão de crédito
              </Button>
            </S.AddCardButton>
          </S.Cards>
        )}

        <S.TermsAndConditions textColor={`${textColor}80`}>
          <P color={`${textColor}80`}>
            Ao clicar em Concluir compra você estará de acordo com os{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://ibioma.com.br/termos-e-condicoes"
            >
              termos e condições
            </a>
          </P>
        </S.TermsAndConditions>
      </S.Content>

      <S.BuyButton
        variant="solid"
        fontWeight="bold"
        disabled={disableButton}
        textColor={backgroundColor}
        backgroundColor={primaryColor}
        onClick={() => onBuyHandler()}
      >
        Concluir compra
      </S.BuyButton>
    </S.CompleteBuy>
  );
};

export default CompleteBuy;
