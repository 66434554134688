import axios from "axios";

import { IOrder, IOrderPayload } from "../../types/order";

const { REACT_APP_ORDER_API_BASE_URL } = process.env;

const createOrder = async (orderData: IOrderPayload): Promise<IOrder> => {
  try {
    const normOrderData = {
      ...orderData,
      entity: "ibioma",
      remoteIp:
        orderData.billingType === "CREDIT_CARD"
          ? (await axios.get("https://ipinfo.io?token=3b4ad5df6132ec")).data.ip
          : undefined,
    };

    const { data } = await axios.post(
      `${REACT_APP_ORDER_API_BASE_URL}/payment-ambiensys`,
      normOrderData
    );

    // console.log(data);

    return data.order;
  } catch (error) {
    throw new Error("Houve um erro ao criar o pagamento.");
  }
};

const services = {
  createOrder,
};

export default services;
