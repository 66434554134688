import { useEffect, useState } from "react";

import SEO from "../../components/atoms/SEO";
import FAQ from "../../components/organisms/FAQ";
import Calculator from "./components/Calculator";
import { Analytics, Cookies } from "../../services";
import Footer from "../../components/molecules/Footer";
import { getCookieFromUser } from "../../utils/cookies";
import ContactUs from "../../components/organisms/ContactUs";
import WhatsappFloating from "../../components/atoms/WhatsappFloating";
import DisclamerPopUp from "../../components/molecules/DisclaimerPopUp";
import HeaderCalculator from "../../components/molecules/HeaderCalculator";

import calculadora from "../../config/calculadora-logistica.json";

const CalculadoraLogistica: React.FC = () => {
  const [popUp, setPopUp] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const sendPageView = async () => {
      if (typeof document !== "undefined") {
        const userId = getCookieFromUser();

        if (!userId) {
          const newUserId = await Cookies.setCookie();

          await Analytics.sendPageView({
            user: newUserId,
            eventLabel: "page-view-calculator",
            location: "logistic-calculator-ambiensys",
          });
        } else {
          await Analytics.sendPageView({
            user: userId,
            eventLabel: "page-view-calculator",
            location: "logistic-calculator-ambiensys",
          });
        }
      }
    };

    sendPageView();
  }, []);

  return (
    <>
      <SEO
        route={calculadora.route}
        title={calculadora.title}
        keywords={calculadora.keywords}
        description={calculadora.description}
      />

      <HeaderCalculator />

      <DisclamerPopUp isOpen={popUp} onClose={() => setPopUp(false)} />

      <Calculator />

      <FAQ />

      <ContactUs location="logistic-calculator-ambiensys" />

      <WhatsappFloating />

      <Footer />
    </>
  );
};

export default CalculadoraLogistica;
