import * as S from "./styles";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import { textColor } from "../../../constants/colors";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onCancelBuy: () => void;
}

const CancelBuyModal: React.FC<IProps> = ({ isOpen, onClose, onCancelBuy }) => {
  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H4 color={textColor}>Deseja mesmo cancelar a compra?</H4>

        <S.Buttons>
          <Button
            variant="solid"
            fontWeight={600}
            onClick={onClose}
            textColor="#fafafa"
            backgroundColor="#4A8415"
          >
            Continuar compra
          </Button>

          <Button
            variant="solid"
            fontWeight={600}
            textColor="#fafafa"
            backgroundColor="#ff0000"
            onClick={() => onCancelBuy()}
          >
            Cancelar compra
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default CancelBuyModal;
