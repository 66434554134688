import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StorageIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9_310)">
        <path
          d="M17.25 4L8.68625 0L0.25 4V5H1.3125V16H3.4375V7H14.0625V16H16.1875V5H17.25V4ZM4.5 6V5H6.625V6H4.5ZM7.6875 6V5H9.8125V6H7.6875ZM10.875 6V5H13V6H10.875Z"
          fill={color || primaryColor}
        />
        <path
          d="M6.625 9H5.5625V8H4.5V11H7.6875V8H6.625V9ZM6.625 13H5.5625V12H4.5V15H7.6875V12H6.625V13ZM10.875 13H9.8125V12H8.75V15H11.9375V12H10.875V13Z"
          fill={color || primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_9_310">
          <rect
            width="17"
            height="16"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StorageIcon;
