import styled, { css } from "styled-components";

import { textColor as txtColor } from "../../../constants/colors";

interface IProps {
  textColor?: string;
  textAlign: "center" | "start";
}

const InputBase = styled.input<IProps>`
  ${({ textColor = txtColor, textAlign }) => css`
    width: 100%;
    resize: none;
    outline: none;
    font-size: 1rem;
    color: ${textColor};
    text-align: ${textAlign};

    &::placeholder {
      color: ${textColor};
    }
  `}
`;

export default InputBase;
