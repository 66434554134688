import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BarCodeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      width="460"
      height="292"
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      viewBox="0 0 460 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833496 291.834V0.166992H42.5002V291.834H0.833496ZM63.3335 291.834V0.166992H105V291.834H63.3335ZM125.833 291.834V0.166992H146.667V291.834H125.833ZM188.334 291.834V0.166992H230V291.834H188.334ZM250.834 291.834V0.166992H313.334V291.834H250.834ZM334.167 291.834V0.166992H355V291.834H334.167ZM396.667 291.834V0.166992H459.167V291.834H396.667Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BarCodeIcon;
