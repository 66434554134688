import axios from "axios";

import { IUser } from "../../types";

const { REACT_APP_CLIENT_API_URL } = process.env;

const getClient = async (clientData: IUser): Promise<IUser> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_API_URL}/ambiensys-client`,
      { ...clientData, entity: "ibioma" }
    );

    return data.user;
  } catch (error) {
    throw new Error("Houve um erro ao criar/obter o cliente no Asaas.");
  }
};

const clientFunctions = {
  getClient,
};

export default clientFunctions;
