import axios from "axios";

import { IProject } from "../../types/projects";
import { IOrderDetails } from "../../types/order";

const { REACT_APP_PROJECTS_API_URL } = process.env;

const getProject = async (
  entity: string,
  id: string,
  token: string
): Promise<IProject> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/project/${entity}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.project;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados do projeto");
  }
};

const getProjectsOrder = async (): Promise<IOrderDetails[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/projects-ambiensys`
    );

    return data.projects;
  } catch (error) {
    throw new Error("Houve um erro ao obter os projetos disponíveis");
  }
};

const getProjectsDetails = async (): Promise<IProject[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/projects-details-amb`
    );

    return data.projects;
  } catch (error) {
    throw new Error(
      "Houve um erro ao obter os detalhes dos projetos disponíveis"
    );
  }
};

const projectsFncts = {
  getProject,
  getProjectsOrder,
  getProjectsDetails,
};

export default projectsFncts;
