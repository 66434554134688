import styled, { css } from "styled-components";

type IconProps = {
  background: boolean;
};

export const Icon = styled.a<IconProps>`
  ${({ background }) => css`
    right: 20px;
    bottom: 40px;
    display: flex;
    padding: 12px;
    z-index: 10000;
    cursor: pointer;
    position: fixed;
    user-select: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${background ? "#00000040" : "transparent"};

    svg {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    @media (max-width: 900px) {
      right: 12px;
      bottom: 20px;
    }
  `}
`;
