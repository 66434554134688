import styled, { css } from "styled-components";

import { tertiaryColor, primaryColor } from "../../../constants/colors";

export const Container = styled.div`
  display: flex;
  border-radius: 8px;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${tertiaryColor};
`;

type UnitProps = {
  isSelected: boolean;
};

export const Unit = styled.div<UnitProps>`
  ${({ isSelected }) => css`
    display: flex;
    cursor: pointer;
    padding: 8px 12px;
    user-select: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    color: ${isSelected ? "#f9fafb" : "#000000"};
    background-color: ${isSelected && primaryColor};
  `}
`;
