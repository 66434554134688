import styled, { css } from "styled-components";

import { primaryColor } from "../../../constants/colors";

type ButtonProps = {
  width?: string;
  maxWidth?: string;
  textColor?: string;
  borderType?: string;
  borderColor?: string;
  backgroundColor?: string;
  fontWeight?: string | number;
  size?: "medium" | "large" | "small";
  variant?: "default" | "solid" | "outline" | "icon" | "dashed" | "backButton";
};

const buttonModifier = {
  solid: (
    backgroundColor: string,
    borderColor: string,
    maxWidth: string
  ) => css`
    width: 100%;
    border-radius: 8px;
    max-width: ${maxWidth};
    background-color: ${backgroundColor};
    border: ${borderColor !== "none" && `1px solid ${borderColor}`};
  `,
  dashed: (borderColor: string, backgroundColor: string) => css`
    border-radius: 24px;
    border: 1px dashed ${borderColor};
    background-color: ${backgroundColor};
  `,
  backButton: (backgroundColor: string) => css`
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 60%;
      height: 60%;
    }
  `,
  outline: (borderColor: string, borderType: string) => css`
    width: 100%;
    background: none;
    border-radius: 12px;
    color: ${primaryColor};
    border: 1px ${borderType} ${borderColor};
  `,
  icon: (backgroundColor: string, size: string) => css`
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
    width: ${size == "small" ? "20px" : "40px"};
    height: ${size == "small" ? "20px" : "40px"};

    svg {
      width: auto;
      line-height: 100%;
      height: ${size == "small" ? "8px" : "12px"};
    }
  `,

  small: () => css`
    padding: 8px 0px;
  `,
  medium: () => css`
    padding: 12px 0px;
  `,
  large: () => css`
    padding: 18px 0px;
  `,
};

const Button = styled.button<ButtonProps>`
  ${({
    width = "100%",
    size = "medium",
    fontWeight = 400,
    maxWidth = "100%",
    variant = "default",
    borderType = "solid",
    textColor = "#fafafa",
    borderColor = "#fafafa",
    backgroundColor = ["solid", "icon"].includes(variant)
      ? "#fafafa0D"
      : "none",
  }) => {
    return css`
      width: ${width};
      font-size: 16px;
      cursor: pointer;
      color: ${textColor};
      font-weight: ${fontWeight};

      ${variant === "outline" &&
      buttonModifier[variant](borderColor, borderType)}
      ${variant === "backButton" && buttonModifier[variant](backgroundColor)}
      ${variant === "dashed" &&
      buttonModifier[variant](borderColor, backgroundColor)}
      ${!["default", "icon"].includes(variant) && buttonModifier[size]()}
      ${variant === "icon" && buttonModifier[variant](backgroundColor, size)}
      ${variant === "solid" &&
      buttonModifier[variant](backgroundColor, borderColor, maxWidth)}

      &:disabled, &[disabled] {
        color: ${textColor}80;
        border-color: ${borderColor}80;
        background-color: ${backgroundColor.length === 9
          ? backgroundColor
          : `${backgroundColor}80`};
        cursor: not-allowed;

        svg {
          opacity: 0.5;
        }
      }
    `;
  }}
`;

export default Button;
