import styled, { css } from "styled-components";

type ContainerProps = {
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  type: "large" | "medium";
};

export const Container = styled.a<ContainerProps>`
  ${({ type, textColor, borderColor, backgroundColor }) => css`
    width: 100%;
    display: flex;
    font-size: 16px;
    flex-wrap: wrap;
    cursor: pointer;
    padding: 12px 0px;
    user-select: none;
    font-weight: bold;
    text-align: center;
    color: ${textColor};
    align-items: center;
    border-radius: 12px;
    text-decoration: none;
    justify-content: center;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
    max-width: ${type === "medium" ? "240px" : "300px"};

    @media (max-width: 720px) {
      max-width: ${type === "medium" && "160px"};
    }

    @media (max-width: 400px) {
      padding: ${type === "medium" && "0 12px"};
      max-width: ${type === "medium" ? "110px" : "200px"};
    }
  `}
`;
