import styled, { css } from "styled-components";

import { tertiaryColor, primaryColor } from "../../../constants/colors";

type IScrolled = {
  scrolled: boolean;
};

export const Header = styled.div<IScrolled>`
  ${({ scrolled }) => css`
    gap: 8px;
    width: 95%;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    position: fixed;
    padding: 0 40px;
    margin: 20px 2.5%;
    align-self: center;
    align-items: center;
    border-radius: 60px;
    justify-content: space-between;
    background-color: ${scrolled ? tertiaryColor : `${tertiaryColor}40`};
  `}
`;

export const Logos = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.a<LogoProps>`
  ${({ logo }) => css`
    width: 180px;
    height: 92px;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    align-items: center;
    justify-content: center;
    background: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 990px) {
      width: 100px;
    }

    @media (max-width: 900px) {
      width: 180px;
    }

    @media (max-width: 500px) {
      width: 120px;
    }
  `}
`;

export const Separator = styled.span`
  content: "";
  width: 1px;
  height: 20px;
  border-left: 1px solid #00000080;
`;

export const Calculators = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 20px;
    display: flex;
    flex-direction: ${isMobile ? "column" : "row"};
  `}
`;

type MobileProps = {
  show?: boolean;
  isMobile?: boolean;
};

export const Calculator = styled.div<MobileProps>`
  ${({ show, isMobile }) => css`
    gap: 12px;
    width: 100%;
    cursor: pointer;
    user-select: none;
    border-radius: 24px;
    align-items: center;
    background-color: transparent;
    display: ${!show ? "none" : "flex"};
    padding: ${isMobile ? "8px" : "8px 20px"};

    :hover {
      background-color: #00000010;
    }
  `}
`;

export const IconCalc = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: ${isMobile ? "28px" : "40px"};
    height: ${isMobile ? "28px" : "40px"};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Items = styled.div`
  gap: 40px;
  display: flex;
  margin-top: 12px;
`;

export const MobileMenu = styled.div<MobileProps>`
  ${({ show }) => css`
    gap: 6px;
    cursor: pointer;
    user-select: none;
    align-items: center;
    flex-direction: column;
    display: ${show ? "flex" : "none"};

    span {
      content: "";
      width: 32px;
      height: 4px;
      background-color: ${primaryColor};
    }
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

export const MobileMenuSections = styled.div<MobileProps>`
  ${({ show }) => css`
    top: 0;
    right: 0;
    bottom: 0;
    gap: 24px;
    width: 50%;
    height: 100vh;
    cursor: pointer;
    user-select: none;
    padding: 20px 8px;
    position: absolute;
    flex-direction: column;
    animation: entrada 0.5s ease-out;
    background-color: ${primaryColor};
    display: ${show ? "flex" : "none"};

    @keyframes entrada {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `}
`;

export const Close = styled.div<MobileProps>`
  ${({ show }) => css`
    width: 20px;
    height: 20px;
    margin-right: 12px;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    display: ${show ? "flex" : "none"};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const MobileSections = styled.div<MobileProps>`
  ${({ show }) => css`
    gap: 40px;
    padding: 12px 0 20px;
    flex-direction: column;
    display: ${show ? "flex" : "none"};
  `}
`;
