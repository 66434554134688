import { useRef, useState } from "react";

import * as S from "./styles";
import {
  Analytics,
  Notifications,
  CalculatorService,
} from "../../../../services";
import {
  energySources,
  transportUnitsObj,
  CONTACT_AMBIENSYS,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../constants";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../constants/colors";
import BuyCredits from "../../../BuyCredits";
import Questions from "./components/Questions";
import { emptyUser } from "../../../../utils/user";
import { Loading, Snackbar } from "../../../../hooks";
import Button from "../../../../components/atoms/Button";
import { maskCPFOrCNPJ } from "../../../../utils/numbers";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H5 from "../../../../components/atoms/Typography/H5";
import { getCookieFromUser } from "../../../../utils/cookies";
import { ICalculatorResult, IUser, TUnits } from "../../../../types";
import ProgressBar from "../../../../components/molecules/ProgressBar";
import { emptyEventCalculatorForm, emptySupplier } from "../../../../utils";
import { IInputValues, IEventCalculatorForm } from "../../../../types/event";
import ChartAndIndicators from "../../../../components/organisms/ChartAndIndicators";

// import calcResultMock from "../../../../mocks/calculatorResult.json";

const validateForm = (
  data: IEventCalculatorForm
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.eventName || data.eventName.length === 0)
    errors.eventName = [
      ...(errors.eventName || []),
      "Nome do evento é obrigatório",
    ];

  if (!data.eventState)
    errors.eventState = [
      ...(errors.eventState || []),
      "Estado do evento é obrigatório",
    ];

  if (!data.eventCity)
    errors.eventCity = [
      ...(errors.eventCity || []),
      "Cidade do evento é obrigatório",
    ];

  if (!data.eventVertical)
    errors.eventVertical = [
      ...(errors.eventVertical || []),
      "Segmento do evento é obrigatório",
    ];

  if (!data.contactName)
    errors.contactName = [
      ...(errors.contactName || []),
      "Nome para contato é obrigatório",
    ];

  if (!data.contactMail)
    errors.contactMail = [
      ...(errors.contactMail || []),
      "E-mail para contato é obrigatório",
    ];

  if (
    data.contactMail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.contactMail)
  )
    errors.contactMail = [...(errors.contactMail || []), "Verifique o email"];

  if (!data.contactPhone)
    errors.contactPhone = [
      ...(errors.contactPhone || []),
      "Telefone para contato é obrigatório",
    ];

  if (!data.eventDuration)
    errors.eventDuration = [
      ...(errors.eventDuration || []),
      "Duração do evento é obrigatório",
    ];

  if (
    data.considerEnergy &&
    (data.energySource === "city-energy" || data.energySource === "both") &&
    (!data.energyExpenses || +data.energyExpenses === 0)
  )
    errors.energyExpenses = [
      ...(errors.energyExpenses || []),
      "Energia é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.considerEnergy &&
    (data.energySource === "generator" || data.energySource === "both") &&
    (!data.generatorFuelExpenses || +data.generatorFuelExpenses === 0)
  )
    errors.generatorFuelExpenses = [
      ...(errors.generatorFuelExpenses || []),
      "Gasto com gerador é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.clientsEmissions &&
    (!data.clientsTransportExpenses || +data.clientsTransportExpenses === 0)
  )
    errors.clientsTransportExpenses = [
      ...(errors.clientsTransportExpenses || []),
      "Distância média dos clientes é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.clientsEmissions &&
    !data.clientsPublicTransportation &&
    !data.clientsSelfOrAppVehicles &&
    !data.clientsWalking &&
    !data.publicShortFlights &&
    !data.publicMediumFlights &&
    !data.publicLongFlights
  ) {
    errors.clientsPublicTransportation = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.clientsSelfOrAppVehicles = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.clientsWalking = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicShortFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicMediumFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicLongFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];
  }

  if (data.clientsEmissions) {
    let percentualValue = 0;
    percentualValue += data.clientsPublicTransportation
      ? +data.clientsPublicTransportation
      : 0;
    percentualValue += data.clientsSelfOrAppVehicles
      ? +data.clientsSelfOrAppVehicles
      : 0;
    percentualValue += data.clientsWalking ? +data.clientsWalking : 0;
    percentualValue += data.publicShortFlights ? +data.publicShortFlights : 0;
    percentualValue += data.publicMediumFlights ? +data.publicMediumFlights : 0;
    percentualValue += data.publicLongFlights ? +data.publicLongFlights : 0;

    if (percentualValue !== 100) {
      errors.clientsPublicTransportation = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.clientsSelfOrAppVehicles = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.clientsWalking = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicShortFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicMediumFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicLongFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];
    }
  }

  return errors;
};

const Calculator: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const [user, setUser] = useState<IUser>(emptyUser);
  const [filtered, setFiltered] = useState<string[]>();
  const [cpfOrCnpj, setCpfOrCnpj] = useState<string>("");
  const [leadPlans, setLeadPlans] = useState<boolean>(false);
  // const [contactHref, setContactHref] = useState<string>("");
  const [buyCredits, setBuyCredits] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [cpfOrCnpjError, setCpfOrCnpjError] = useState<string[]>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [suppliers, setSuppliers] = useState<IInputValues[]>([emptySupplier]);
  const [calculatorForm, setCalculatorForm] = useState<IEventCalculatorForm>(
    emptyEventCalculatorForm
  );
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();

  const questionsTop = useRef<HTMLDivElement>(null);

  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onChangeHandler = (
    key: keyof IEventCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (key === "considerEnergy") {
        return {
          ...curr,
          [key]: value === "Sim" ? true : false,
        };
      }

      if (
        key === "internalTransportUnit" ||
        key === "employeesTransportUnit" ||
        key === "employeesPublicTransportUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "energySource") {
        return {
          ...curr,
          [key]: energySources[+value],
        };
      }

      if (key === "generatorFuelUnit") {
        return {
          ...curr,
          [key]: value ? ("R$" as TUnits) : ("l" as TUnits),
        };
      }

      if (key === "cookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (key === "clientsEmissions" || key === "suppliersEmissions") {
        return {
          ...curr,
          [key]: value === 0 ? true : false,
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);

      const currErrorCpfOrCnpj =
        cpfOrCnpj.length !== 11 && cpfOrCnpj.length !== 14
          ? ["Verifique o preenchimento do CPF ou CNPJ"]
          : setCpfOrCnpjError(undefined);

      if (
        (currErrors && Object.keys(currErrors).length > 0) ||
        currErrorCpfOrCnpj
      ) {
        if (currErrors && Object.keys(currErrors).length > 0) {
          setErrors(currErrors);

          if (
            currErrors.energyExpenses.length > 0 ||
            currErrors.generatorFuelExpenses.length > 0
          ) {
            setQuestionNumber(1);
          } else if (
            currErrors.clientsPublicTransportation.length > 0 ||
            currErrors.clientsSelfOrAppVehicles.length > 0 ||
            currErrors.clientsWalking.length > 0 ||
            currErrors.publicShortFlights.length > 0 ||
            currErrors.publicMediumFlights.length > 0 ||
            currErrors.publicLongFlights.length > 0
          )
            setQuestionNumber(2);
          else if (currErrors.eventDuration.length > 0) setQuestionNumber(3);
        }

        if (Object.keys(currErrors).length === 0 && Object.keys(errors).length)
          setErrors({});

        if (currErrorCpfOrCnpj) setCpfOrCnpjError(currErrorCpfOrCnpj);

        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      setErrors({});

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        user: userId || "",
        eventLabel: "calculate-footprint",
        location: "event-calculator-ambiensys",
      });

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const labelCpfOrCnpj = cpfOrCnpj.length === 11 ? "cpf" : "cnpj";

      const result = await CalculatorService.sendEventInformation({
        ...calculatorForm,
        userId: userId || "",
        cpfOrCnpj: labelCpfOrCnpj,
        contactMail: calculatorForm.contactMail.toLowerCase().trim(),
        energyUnit:
          calculatorForm.energyExpenses && !calculatorForm.energyUnit
            ? "KWh"
            : calculatorForm.energyUnit,
        cookingGasUnit:
          calculatorForm.cookingGasExpenses && !calculatorForm.cookingGasUnit
            ? "Kg"
            : calculatorForm.cookingGasUnit,
        generatorFuelUnit:
          calculatorForm.generatorFuelExpenses &&
          !calculatorForm.generatorFuelUnit
            ? "l"
            : calculatorForm.generatorFuelUnit,
        internalTransportUnit:
          calculatorForm.internalTransportExpenses &&
          !calculatorForm.internalTransportUnit
            ? "R$"
            : calculatorForm.internalTransportUnit,
        employeesTransportUnit:
          calculatorForm.employeesTransportExpenses &&
          !calculatorForm.employeesTransportUnit
            ? "R$"
            : calculatorForm.employeesTransportUnit,
        employeesPublicTransportUnit:
          calculatorForm.employeesPublicTransportExpenses &&
          !calculatorForm.employeesPublicTransportUnit
            ? "R$"
            : calculatorForm.employeesPublicTransportUnit,
        generatorFuelType: "oleo diesel (comercial)",
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        energyExpenses:
          calculatorForm.considerEnergy &&
          (calculatorForm.energySource === "city-energy" ||
            calculatorForm.energySource === "both")
            ? calculatorForm.energyExpenses
            : undefined,
        generatorFuelExpenses:
          calculatorForm.considerEnergy &&
          (calculatorForm.energySource === "generator" ||
            calculatorForm.energySource === "both")
            ? calculatorForm.generatorFuelExpenses
            : undefined,
      });

      setCalculatorResult(result);

      setUser((curr) => ({
        ...curr,
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        mail: calculatorForm.contactMail,
        company: calculatorForm.eventName,
        cpfOrCnpj: labelCpfOrCnpj,
        companyCity: calculatorForm.eventCity,
        companyState: calculatorForm.eventState,
        contactName: calculatorForm.contactName,
        contactPhone: calculatorForm.contactPhone,
      }));

      localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "event");
      localStorage.setItem(LOCAL_STORAGE_CALCULATION_ID_KEY, result.id);

      // const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      // const text = `
      // Olá! Gostaria de saber mais sobre planos e recorrência das emissões de carbono do meu evento. Aqui estão os meus dados:

      // * Nome - ${calculatorForm.contactName}
      // * Evento - ${calculatorForm.eventName}
      // * Emissões - ${result.totalEmissions
      //   .toFixed(2)
      //   .replace(".", ",")} toneladas de CO2e
      // `;

      // const encodedText = encodeURIComponent(text);

      // setContactHref(url + "?text=" + encodedText);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyEventCalculatorForm);
    setErrors({});
    setCpfOrCnpj("");
    setCpfOrCnpjError(undefined);
    // setContactHref("");
    setQuestionNumber(1);
    setLeadPlans(false);

    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      eventLabel: "new-consult",
      location: "event-calculator-ambiensys",
    });
  };

  const onContactClickHandler = async () => {
    try {
      showLoading();

      const userId = getCookieFromUser();

      await Notifications.sendEmail({
        subject: "Lead IBIOMA - Planos e Recorrência",
        templateId: "plans-lead-ambiensys",
        to: [CONTACT_AMBIENSYS],
        variables: {
          name: calculatorForm.eventName,
          email: calculatorForm.contactMail,
          phone: calculatorForm.contactPhone,
          calculator: "Eventos",
          totalEmissions:
            calculatorResult?.totalEmissions.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          year: new Date().getFullYear().toString(),
        },
      });

      newSuccess(
        "Sua demonstração de interesse foi enviada para nossa equipe, que entrará em contato em breve com você!"
      );

      setLeadPlans(true);

      await Analytics.sendClick({
        user: userId || "",
        eventLabel: "get-in-touch-plans",
        location: "event-calculator-ambiensys",
        meta: { section: "calculator", method: "whatsapp" },
      });
    } catch (error) {
      newError("Houve um erro ao enviar a mensagem para nossa equipe");
    } finally {
      hideLoading();
    }
  };

  const onCreateSupplier = () => {
    setSuppliers((curr) => [...curr, emptySupplier]);
  };

  const onAddSuppliers = (
    index: number,
    key: keyof IInputValues,
    value: string
  ) => {
    setSuppliers((curr) => {
      const newData = [...curr];
      newData[index] = { ...newData[index], [key]: value };

      return newData;
    });
  };

  const onRemoveSuppliers = (index: number) => {
    setSuppliers((curr) => {
      const newData = [...curr];
      newData.splice(index, 1);

      return newData;
    });
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const booleanOptions = ["Sim", "Não"];

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  return (
    <S.Container result={!!calculatorResult}>
      {buyCredits ? (
        <BuyCredits
          user={user}
          setUser={(val) => setUser(val)}
          onCancelBuy={() => setBuyCredits(false)}
          credits={Math.ceil(calculatorResult?.totalEmissions || 1)}
        />
      ) : (
        <>
          <S.TitleAndProgress ref={questionsTop}>
            <S.TitleAndDescription>
              <H1 color={textColor} fontWeight="bold">
                Calculadora
              </H1>

              {!calculatorResult && (
                <H5 color={textColor}>
                  Detalhe sua operação respondendo às perguntas abaixo e
                  descubra sua pegada mensal de carbono
                </H5>
              )}
            </S.TitleAndDescription>

            {!calculatorResult && (
              <ProgressBar
                currentQuestion={questionNumber}
                numberOfQuestions={4}
              />
            )}
          </S.TitleAndProgress>

          <S.Content
            resultBox={!!calculatorResult}
            marginTop={questionNumber !== 2}
          >
            {calculatorResult && (
              <S.ChartResult>
                <H2 color={textColor} fontWeight="bold">
                  A pegada mensal de carbono de seu evento é:
                </H2>

                <H3 color={textColor}>
                  Clique em Compensar minhas emissões, para escolher o projeto
                  gerador de créditos de carbono, que mais combina com a sua
                  empresa, e finalize a aquisição. Caso queira saber mais sobre
                  planos e recorrência de neutralizações de suas emissões,
                  clique no botão Quero saber mais sobre planos e recorrência e
                  fale com nosso time!
                </H3>

                <S.ResultButtons>
                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    borderColor={primaryColor}
                    textColor={backgroundColor}
                    backgroundColor={primaryColor}
                    onClick={() => setBuyCredits(true)}
                  >
                    Compensar minhas emissões
                  </Button>

                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    disabled={leadPlans}
                    textColor={primaryColor}
                    borderColor={primaryColor}
                    backgroundColor={backgroundColor}
                    onClick={() => onContactClickHandler()}
                  >
                    {leadPlans
                      ? "Mensagem enviada ao time!"
                      : "Quero saber mais sobre planos e recorrência"}
                  </Button>
                </S.ResultButtons>
              </S.ChartResult>
            )}

            <S.CalculatorBox resultBox={!!calculatorResult}>
              {calculatorResult ? (
                <>
                  <S.Charts>
                    <S.ChartBox>
                      <ChartAndIndicators result={calculatorResult} />
                    </S.ChartBox>
                  </S.Charts>

                  <S.Buttons>
                    <Button
                      variant="solid"
                      fontWeight="bold"
                      borderColor={primaryColor}
                      textColor={backgroundColor}
                      backgroundColor={primaryColor}
                      onClick={() => setBuyCredits(true)}
                    >
                      Compensar emissões
                    </Button>

                    <Button
                      variant="solid"
                      fontWeight="bold"
                      textColor={primaryColor}
                      borderColor={primaryColor}
                      backgroundColor={backgroundColor}
                      onClick={() => onNewConsultHandler()}
                    >
                      Nova consulta
                    </Button>
                  </S.Buttons>
                </>
              ) : (
                <Questions
                  search={search}
                  errors={errors}
                  suppliers={suppliers}
                  question={questionNumber}
                  filteredOptions={filtered}
                  formFields={calculatorForm}
                  cpfOrCnpj={cpfOrCnpj || ""}
                  booleanOptions={booleanOptions}
                  cpfOrCnpjError={cpfOrCnpjError}
                  onAheadClick={() => onAheadClick()}
                  transportOptions={transportUnitsOptions}
                  setCpfOrCnpj={(val) => setCpfOrCnpj(val)}
                  onPreviousClick={() => onPreviousClick()}
                  createSupplier={() => onCreateSupplier()}
                  onSearch={(val, opt) => onSearch(val, opt)}
                  onClearSearch={() => setFiltered(undefined)}
                  onCalculateClick={() => onCalculateHandler()}
                  removeSuppliers={(index) => onRemoveSuppliers(index)}
                  onChangeForm={(key, val) => onChangeHandler(key, val)}
                  addSuppliers={(index, key, value) =>
                    onAddSuppliers(index, key, value)
                  }
                />
              )}
            </S.CalculatorBox>
          </S.Content>
        </>
      )}

      <S.Disclamer>
        <P color="#00000060">
          Disclamer: O cálculo da pegada de carbono em nossa calculadora virtual
          é realizado com base em informações precisas, transparentes e
          atualizadas sobre ações e iniciativas, fornecidas unilateralmente pelo
          cliente. Após o fornecimento das informações de maneira unilateral, o
          selo IBIOMA será emitido e devidamente fornecido ao cliente. Ou O
          cálculo da pegada de carbono em nossa calculadora virtual é realizado
          com base em informações precisas, transparentes e atualizadas sobre
          ações e iniciativas, fornecidas unilateralmente pelo cliente. A equipe
          responsável pela Calculadora IBIOMA não se responsabiliza por
          discrepâncias causadas por dados incorretos ou desatualizados.
        </P>
      </S.Disclamer>
    </S.Container>
  );
};

export default Calculator;
