import styled, { css } from "styled-components";
import { tertiaryColor, primaryColor } from "../../../constants/colors";

export const Container = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
`;

export const OptionBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${tertiaryColor};
`;

type OptionProps = {
  isSelected: boolean;
};

export const OptionSelected = styled.div<OptionProps>`
  ${({ isSelected }) => css`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${primaryColor};
    display: ${isSelected ? "flex" : "none"};
  `}
`;
