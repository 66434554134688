/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import {
  IOrder,
  TBilling,
  ICardsData,
  ICreditCard,
  IOrderPayload,
  IOrderDetails,
  TBillingOption,
  IProjectsOrder,
  ICreditCardHolderInfo,
} from "../../types/order";
import {
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../constants";
import {
  textColor,
  primaryColor,
  tertiaryColor,
  backgroundColor,
} from "../../constants/colors";
import { Clients, Order, Project } from "../../services";
import { emptyProjectOrder } from "../../utils";
import { Loading, Snackbar } from "../../hooks";
import Button from "../../components/atoms/Button";
import CompleteBuy from "./components/CompleteBuy";
import BillingInfo from "./components/BillingInfo";
import H1 from "../../components/atoms/Typography/H1";
import H3 from "../../components/atoms/Typography/H3";
import { paymentMethod } from "../../constants/order";
import { ICalculatorResult, IUser } from "../../types";
import { creditCardBrand, emptyCardInfo } from "../../utils/order";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import CancelBuyModal from "../../components/molecules/CancelBuyModal";
import BuyProjectsMenu from "../../components/organisms/BuyProjectsMenu";

const validateForm = (data: ICardsData): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.creditCard.number)
    errors.number = [
      ...(errors.number || []),
      "Número do cartão é obrigatório",
    ];

  if (!data.creditCard.ccv)
    errors.ccv = [...(errors.ccv || []), "CCV é obrigatório"];

  if (!data.creditCard.holderName)
    errors.holderName = [
      ...(errors.holderName || []),
      "Nome no cartão é obrigatório",
    ];

  if (!data.creditCard.expiryMonth)
    errors.expiryMonth = [...(errors.expiryMonth || []), "Mês"];

  if (!data.creditCard.expiryYear)
    errors.expiryYear = [...(errors.expiryYear || []), "Ano"];

  if (!data.creditCardHolderInfo.addressNumber)
    errors.addressNumber = [
      ...(errors.addressNumber || []),
      "Número do endereço é obrigatório",
    ];

  if (!data.creditCardHolderInfo.address)
    errors.address = [...(errors.address || []), "Endereço é obrigatório"];

  if (!data.creditCardHolderInfo.cpfCnpj)
    errors.cpfCnpj = [...(errors.cpfCnpj || []), "CPF/CNPJ é obrigatório"];

  if (!data.creditCardHolderInfo.email)
    errors.email = [...(errors.email || []), "E-mail do contato é obrigatório"];

  if (!data.creditCardHolderInfo.phone)
    errors.phone = [
      ...(errors.phone || []),
      "Telefone do contato é obrigatório",
    ];

  if (!data.creditCardHolderInfo.name)
    errors.name = [...(errors.name || []), "Nome do contato é obrigatório"];

  if (!data.creditCardHolderInfo.postalCode)
    errors.postalCode = [...(errors.postalCode || []), "CEP é obrigatório"];

  return errors;
};

interface IProps {
  user: IUser;
  credits: number;
  onCancelBuy: () => void;
  setUser: (val: IUser) => void;
  calculatorResult?: ICalculatorResult;
}

const BuyCredits: React.FC<IProps> = ({
  user,
  setUser,
  credits,
  onCancelBuy,
  calculatorResult,
}) => {
  const [billing, setBilling] = useState<IOrder>();
  const [payment, setPayment] = useState<TBilling>();
  const [newCard, setNewCard] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [completeBuy, setCompleteBuy] = useState<boolean>(false);
  const [billingInfo, setBillingInfo] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [newCardData, setNewCardData] = useState<ICardsData>(emptyCardInfo);
  const [selected, setSelected] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<IProjectsOrder>();
  const [biggerTextarea, setBiggerTextarea] = useState(
    window.innerWidth <= 540
  );
  const [availableProjects, setAvalaibleProjects] =
    useState<IOrderDetails[]>(emptyProjectOrder);

  const buyCredits = useRef<HTMLDivElement>(null);

  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    const handleResize = () => {
      setBiggerTextarea(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [biggerTextarea]);

  useEffect(() => {
    const getProjects = async () => {
      const projectsData = await Project.getProjectsOrder();

      setAvalaibleProjects(projectsData);
    };

    getProjects();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onBackClick = () => {
    if (completeBuy && !billingInfo) {
      setNewCard(false);
      setCompleteBuy(false);
      setPayment(undefined);
      setNewCardData(emptyCardInfo);

      return;
    }

    setCancelModal(true);
  };

  const onCancelBuyHandler = () => {
    setCancelModal(false);
    onCancelBuy();
    setSelected("");
    setSelectedProject(undefined);
  };

  const onSelectProject = (id: string) => {
    if (selected === id) {
      setSelectedProject(undefined);
      setSelected("");

      return;
    }

    const projectSelected = availableProjects.filter(
      (item) => item.projectId === id && item
    );

    projectSelected &&
      projectSelected.length &&
      setSelectedProject({
        quantity: credits,
        pricing: projectSelected[0].pricing,
        name: projectSelected[0].projectName,
        projectId: projectSelected[0].projectId,
      } as IProjectsOrder);
    setSelected(id);
  };

  const onCompleteBuy = async () => {
    try {
      showLoading();

      const newUser = await Clients.getClient({
        mail: user.mail,
        entity: "ibioma",
        company: user.company,
        cpfOrCnpj: user.cpfOrCnpj,
        contactName: user.contactName,
        companyCity: user.companyCity,
        companyState: user.companyState,
        contactPhone: user.contactPhone,
        cpf: (user.cpf || "").replace(/[^0-9]/g, ""),
        cnpj: (user.cnpj || "").replace(/[^0-9]/g, ""),
      });

      setUser({
        ...user,
        ...newUser,
      });

      setCompleteBuy(true);
      buyCredits.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      newError("Houve um erro ao criar o cliente");
    } finally {
      hideLoading();
    }
  };

  const onSelectPaymentMethod = (value: TBillingOption) => {
    if (!value) return;

    setPayment(
      Object.keys(paymentMethod).find(
        (key) => paymentMethod[key as TBilling] === value
      ) as TBilling
    );
  };

  const onChangeCardInfo = (key: keyof ICreditCard, val: string) => {
    if (key === "number") {
      const currBrand = creditCardBrand(val);

      setNewCardData((curr) => ({
        ...curr,
        creditCard: {
          ...curr.creditCard,
          [key]: val,
          brand: currBrand,
        },
      }));

      return;
    }

    setNewCardData((curr) => ({
      ...curr,
      creditCard: {
        ...curr.creditCard,
        [key]: val,
      },
    }));
  };

  const onChangeCardHolderInfo = (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => {
    setNewCardData((curr) => ({
      ...curr,
      creditCardHolderInfo: {
        ...curr.creditCardHolderInfo,
        [key]: val,
      },
    }));
  };

  const onDiscardNewCard = () => {
    setErrors({});
    setNewCard(false);
    setNewCardData(emptyCardInfo);
  };

  const onBuyClick = async () => {
    try {
      showLoading();

      if (payment === "CREDIT_CARD" && newCard) {
        const currErrors = validateForm(newCardData);

        if (currErrors && Object.keys(currErrors).length > 0) {
          setErrors(currErrors);
          newError("Verifique as informações do cartão");

          return;
        }
      }

      if (selectedProject) {
        setErrors({});

        const calculator = localStorage.getItem(LOCAL_STORAGE_CALCULATOR_KEY);
        const calculationId = localStorage.getItem(
          LOCAL_STORAGE_CALCULATION_ID_KEY
        );

        // Primeira cobrança terá vencimento no dia da compra
        const date = new Date();
        const month =
          date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1;
        const normDate = `${date.getFullYear()}-${month}-${date.getDate()}`;

        const creditCardData = (() => {
          return {
            creditCardToken: undefined,
            creditCard: newCardData.creditCard,
            creditCardHolderInfo: newCardData.creditCardHolderInfo,
          };
        })();

        const orderPayload: IOrderPayload = {
          client: user,
          plan: "single",
          credits: credits,
          dueDate: normDate,
          project: selectedProject,
          classification: "neutral",
          customer: user.asaasId || "",
          billingType: payment || "PIX",
          calculatorResult: calculatorResult,
          calculator: calculator || "admin",
          calculationId:
            calculationId || "127ee1e5-0215-4a07-87d6-85c2017d4437",
          value: (credits || 1) * selectedProject.pricing,
          simplesNacional: user.simplesNacional || false,
          creditCard:
            newCard && creditCardData ? creditCardData.creditCard : undefined,
          creditCardHolderInfo:
            newCard && creditCardData
              ? creditCardData.creditCardHolderInfo
              : undefined,
        };

        const orderResponse = await Order.createOrder(orderPayload);

        setBilling(orderResponse);

        setBillingInfo(true);
        setNewCardData((curr) => ({
          ...curr,
          creditCard: {
            ...curr.creditCard,
            ccv: "***",
            number: `************${curr.creditCard.number.substring(12)}`,
          },
        }));

        localStorage.removeItem(LOCAL_STORAGE_CALCULATION_ID_KEY);

        newSuccess("Ordem de compra criada com sucesso!");
      }
    } catch (error) {
      newError("Houve um erro ao finalizar a compra");
    } finally {
      hideLoading();
    }
  };

  const projectWidth = "50%";
  const tCo2Width = "25%";
  const pricingWidth = "25%";

  return (
    <>
      <S.Content ref={buyCredits}>
        {!billingInfo && (
          <S.Header>
            <Button
              size="small"
              variant="backButton"
              onClick={() => onBackClick()}
              backgroundColor={tertiaryColor}
            >
              <AvailableIcons option="chevron" color={textColor} />
            </Button>
          </S.Header>
        )}

        {!completeBuy && !billingInfo && (
          <S.BuyCredits>
            <H1 color={textColor}>Portfólio</H1>

            <S.ProjectsSelector>
              <BuyProjectsMenu
                selected={selected}
                labelWeight="regular"
                labelColor={textColor}
                label="Selecione projetos"
                options={availableProjects}
                onSelect={(val) => onSelectProject(val)}
                placeholder={
                  selectedProject?.name
                    ? selectedProject?.name
                    : "Selecione os projetos de interesse"
                }
              />
            </S.ProjectsSelector>

            <S.Projects>
              <S.HeaderProjects>
                <S.TitleCustom
                  fontWeight="bold"
                  color={textColor}
                  width={projectWidth}
                >
                  Projeto
                </S.TitleCustom>

                <S.TitleCustom
                  width={tCo2Width}
                  color={textColor}
                  fontWeight="bold"
                >
                  tCO<sub>2</sub>
                </S.TitleCustom>

                <S.TitleCustom
                  color={textColor}
                  fontWeight="bold"
                  width={pricingWidth}
                >
                  Preço
                </S.TitleCustom>
              </S.HeaderProjects>

              <S.ProjectsList
                borderColor={tertiaryColor}
                bothBorders={selectedProject ? true : false}
              >
                {selectedProject ? (
                  <S.Project>
                    <S.TitleCustom width={projectWidth} color={textColor}>
                      {selectedProject.name}
                    </S.TitleCustom>

                    <S.TitleCustom color={textColor} width={tCo2Width}>
                      {credits}
                    </S.TitleCustom>

                    <S.TitleCustom width={pricingWidth} color={textColor}>
                      R${" "}
                      {selectedProject.pricing.toLocaleString("pt-BR", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </S.TitleCustom>
                  </S.Project>
                ) : (
                  <H3 color={`${textColor}50`}>Nenhum projeto selecionado</H3>
                )}
              </S.ProjectsList>

              {selectedProject && (
                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    fontWeight="bold"
                    width={projectWidth + tCo2Width}
                  >
                    Total a pagar
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    R${" "}
                    {(credits * selectedProject.pricing).toLocaleString(
                      "pt-BR",
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                    )}
                  </S.TotalValue>
                </S.Total>
              )}
            </S.Projects>

            <Button
              variant="solid"
              fontWeight="600"
              disabled={!selected}
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onCompleteBuy()}
            >
              Finalizar
            </Button>
          </S.BuyCredits>
        )}

        {completeBuy && !billingInfo && selectedProject && (
          <CompleteBuy
            user={user}
            plan="single"
            errors={errors}
            addNewCard={newCard}
            newCardInfo={newCardData}
            onBuyHandler={() => onBuyClick()}
            selectedProject={selectedProject}
            onAddNewCard={() => setNewCard(true)}
            onDiscardNewCard={() => onDiscardNewCard()}
            totalValue={(credits || 1) * selectedProject.pricing}
            onSelectPayment={(val) => onSelectPaymentMethod(val)}
            onChangeCardInfo={(key, val) => onChangeCardInfo(key, val)}
            onChangeCardHolderInfo={(key, val) =>
              onChangeCardHolderInfo(key, val)
            }
            selectedPayment={Object.keys(paymentMethod).findIndex(
              (key) => key === payment
            )}
          />
        )}

        {completeBuy && billingInfo && (
          <BillingInfo
            payment={payment || "PIX"}
            biggerTextarea={biggerTextarea}
            invoiceUrl={billing?.invoiceUrl}
            totalValue={billing?.billingValue || 0}
            paymentStatus={billing?.status || "PENDING"}
            orderAsaasId={billing?.orderAsaasId || "pay_empty"}
            copyIdentification={billing ? billing.identificationField : ""}
            card={
              payment === "CREDIT_CARD" && newCard ? newCardData : undefined
            }
          />
        )}

        {cancelModal && (
          <CancelBuyModal
            isOpen={cancelModal}
            onClose={() => setCancelModal(false)}
            onCancelBuy={() => onCancelBuyHandler()}
          />
        )}
      </S.Content>
    </>
  );
};

export default BuyCredits;
