import styled from "styled-components";

import { tertiaryColor } from "../../../constants/colors";

export const Card = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: ${tertiaryColor}80;
`;

export const Texts = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const CloseIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
