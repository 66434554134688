import * as S from "./styles";
import {
  maskCEP,
  maskCPFOrCNPJ,
  maskPhoneNumber,
} from "../../../utils/numbers";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import InputText2 from "../../molecules/InputText2";
import { maskCreditCard } from "../../../utils/order";
import { textColor, tertiaryColor } from "../../../constants/colors";
import { ICreditCard, ICreditCardHolderInfo } from "../../../types/order";

interface IProps {
  cardInfo: ICreditCard;
  onDiscard: () => void;
  onAddNewCard?: () => void;
  errors: { [key: string]: string[] };
  cardHolderInfo: ICreditCardHolderInfo;
  onChangeCardInfo: (key: keyof ICreditCard, val: string) => void;
  onChangeCardHolderInfo: (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => void;
}

const CreditCardInfo: React.FC<IProps> = ({
  errors,
  cardInfo,
  onDiscard,
  cardHolderInfo,
  onChangeCardInfo,
  onChangeCardHolderInfo,
}) => {
  return (
    <S.Container>
      <H4 color={textColor} fontWeight="bold">
        Dados do cartão
      </H4>

      <S.CardInfo>
        <InputText2
          key="number"
          labelWeight="regular"
          errors={errors.number}
          labelColor={textColor}
          label="Número do cartão"
          brandIcon={cardInfo.brand}
          backgroundColor={tertiaryColor}
          value={maskCreditCard(cardInfo.number || "")}
          onChange={(val) =>
            onChangeCardInfo("number", val.replace(/[^0-9]/g, ""))
          }
        />

        <InputText2
          key="holderName"
          labelWeight="regular"
          labelColor={textColor}
          errors={errors.holderName}
          label="Nome impresso no cartão"
          backgroundColor={tertiaryColor}
          value={(cardInfo.holderName || "").toUpperCase()}
          onChange={(val) => onChangeCardInfo("holderName", val.toUpperCase())}
        />

        <InputText2
          key="ccv"
          charLimit={3}
          errors={errors.ccv}
          labelWeight="regular"
          labelColor={textColor}
          value={cardInfo.ccv || ""}
          backgroundColor={tertiaryColor}
          label="Código de segurança (CCV)"
          onChange={(val) => onChangeCardInfo("ccv", val)}
        />

        <S.ExpirationDate>
          <H4 color={textColor}>Validade</H4>

          <S.Inputs backgroundColor={tertiaryColor}>
            <InputText2
              label=""
              charLimit={2}
              key="expiryMonth"
              labelWeight="regular"
              labelColor={textColor}
              errors={errors.expiryMonth}
              value={cardInfo.expiryMonth || ""}
              onChange={(val) =>
                onChangeCardInfo("expiryMonth", val.replace(/[^0-9]/g, ""))
              }
            />
            /
            <InputText2
              label=""
              charLimit={2}
              key="expiryYear"
              labelWeight="regular"
              labelColor={textColor}
              errors={errors.expiryYear}
              value={cardInfo.expiryYear || ""}
              onChange={(val) =>
                onChangeCardInfo("expiryYear", val.replace(/[^0-9]/g, ""))
              }
            />
          </S.Inputs>
        </S.ExpirationDate>
      </S.CardInfo>

      <H4 color={textColor} fontWeight="bold">
        Dados do proprietário do cartão
      </H4>

      <S.CardHolderInfo>
        <InputText2
          key="name"
          errors={errors.name}
          label="Nome completo"
          labelWeight="regular"
          labelColor={textColor}
          backgroundColor={tertiaryColor}
          value={cardHolderInfo.name || ""}
          onChange={(val) => onChangeCardHolderInfo("name", val)}
        />

        <InputText2
          key="email"
          label="E-mail"
          labelWeight="regular"
          errors={errors.email}
          labelColor={textColor}
          backgroundColor={tertiaryColor}
          value={cardHolderInfo.email || ""}
          onChange={(val) => onChangeCardHolderInfo("email", val)}
        />

        <InputText2
          key="phone"
          charLimit={15}
          label="Telefone"
          labelWeight="regular"
          errors={errors.phone}
          labelColor={textColor}
          backgroundColor={tertiaryColor}
          value={maskPhoneNumber(cardHolderInfo.phone)}
          onChange={(val) =>
            onChangeCardHolderInfo("phone", val.replace(/[^0-9]/g, ""))
          }
        />

        <InputText2
          key="cpfCnpj"
          charLimit={18}
          label="CPF ou CNPJ"
          labelWeight="regular"
          labelColor={textColor}
          errors={errors.cpfCnpj}
          backgroundColor={tertiaryColor}
          value={maskCPFOrCNPJ(cardHolderInfo.cpfCnpj || "")}
          onChange={(val) =>
            onChangeCardHolderInfo("cpfCnpj", val.replace(/[^0-9]/g, ""))
          }
        />

        <InputText2
          label="CEP"
          charLimit={9}
          key="postalCode"
          labelWeight="regular"
          labelColor={textColor}
          errors={errors.postalCode}
          backgroundColor={tertiaryColor}
          value={maskCEP(cardHolderInfo.postalCode || "")}
          onChange={(val) =>
            onChangeCardHolderInfo("postalCode", val.replace(/[^0-9]/g, ""))
          }
        />

        <InputText2
          key="address"
          label="Endereço"
          labelWeight="regular"
          labelColor={textColor}
          errors={errors.address}
          backgroundColor={tertiaryColor}
          value={cardHolderInfo.address || ""}
          onChange={(val) => onChangeCardHolderInfo("address", val)}
        />

        <InputText2
          label="Número"
          key="addressNumber"
          labelWeight="regular"
          labelColor={textColor}
          errors={errors.addressNumber}
          backgroundColor={tertiaryColor}
          value={cardHolderInfo.addressNumber || ""}
          onChange={(val) => onChangeCardHolderInfo("addressNumber", val)}
        />

        <InputText2
          label="Complemento"
          labelWeight="regular"
          labelColor={textColor}
          key="addressComplement"
          backgroundColor={tertiaryColor}
          value={cardHolderInfo.addressComplement || ""}
          onChange={(val) => onChangeCardHolderInfo("addressComplement", val)}
        />
      </S.CardHolderInfo>

      <S.Buttons direction="row">
        <Button
          width="300px"
          variant="solid"
          fontWeight="600"
          textColor={textColor}
          onClick={() => onDiscard()}
          backgroundColor={tertiaryColor}
        >
          Descartar cartão
        </Button>
      </S.Buttons>
    </S.Container>
  );
};

export default CreditCardInfo;
