import { useRef, useState } from "react";

import * as S from "./styles";
import {
  IUser,
  IProduction,
  ICalculatorForm,
  ICalculatorResult,
} from "../../../../types";
import {
  Analytics,
  Notifications,
  CalculatorService,
} from "../../../../services";
import {
  transportUnitsObj,
  CONTACT_AMBIENSYS,
  airConditioningTypes,
  airConditioningTypesObj,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../constants";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../constants/colors";
import BuyCredits from "../../../BuyCredits";
import Questions from "./components/Questions";
import { emptyUser } from "../../../../utils/user";
import { Loading, Snackbar } from "../../../../hooks";
import Button from "../../../../components/atoms/Button";
import { maskCPFOrCNPJ } from "../../../../utils/numbers";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H5 from "../../../../components/atoms/Typography/H5";
import { getCookieFromUser } from "../../../../utils/cookies";
import ProgressBar from "../../../../components/molecules/ProgressBar";
import { emptyCalculatorForm, emptyProduction } from "../../../../utils";
import ExcludeModal from "../../../../components/molecules/ExcludeModal";
import ChartAndIndicators from "../../../../components/organisms/ChartAndIndicators";

// import calcResultMock from "../../../../mocks/calculatorResult.json";

export interface ProductionErrors {
  product: string | undefined;
  quantity: string | undefined;
  dimension: string | undefined;
  production: string | undefined;
}

const validateProduction = (data?: IProduction[]): ProductionErrors[] => {
  const errors = (() => {
    if (data && data.length > 0) {
      const productionErrors = data.map((item) => {
        const production = !item.production
          ? "Selecione uma produção"
          : undefined;
        const product =
          item.product === "" || !item.product
            ? "Selecione um produto"
            : undefined;
        const dimension =
          item.dimension === "" ? "Insira a dimensão" : undefined;
        const quantity =
          item.production === "Bebidas" && item.quantity === ""
            ? "Insira a quantidade"
            : undefined;

        return {
          product: product,
          quantity: quantity,
          dimension: dimension,
          production: production,
        };
      });

      return productionErrors;
    }

    return [
      {
        dimension: "Insira a dimensão",
        product: "Selecione um produto",
        quantity: "Insira a quantidade",
        production: "Selecione uma produção",
      },
    ];
  })();

  return errors;
};

const validateForm = (data: ICalculatorForm): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.companyName || data.companyName.length === 0)
    errors.companyName = [
      ...(errors.companyName || []),
      "Nome da empresa é obrigatório",
    ];

  if (!data.companyState)
    errors.companyState = [
      ...(errors.companyState || []),
      "Estado da empresa é obrigatório",
    ];

  if (!data.companyCity)
    errors.companyCity = [
      ...(errors.companyCity || []),
      "Cidade da empresa é obrigatório",
    ];

  if (!data.companyVertical)
    errors.companyVertical = [
      ...(errors.companyVertical || []),
      "Segmento da empresa é obrigatório",
    ];

  if (!data.contactName)
    errors.contactName = [
      ...(errors.contactName || []),
      "Nome para contato é obrigatório",
    ];

  if (!data.contactMail)
    errors.contactMail = [
      ...(errors.contactMail || []),
      "E-mail para contato é obrigatório",
    ];

  if (
    data.contactMail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.contactMail)
  )
    errors.contactMail = [...(errors.contactMail || []), "Verifique o email"];

  if (!data.contactPhone)
    errors.contactPhone = [
      ...(errors.contactPhone || []),
      "Telefone para contato é obrigatório",
    ];

  return errors;
};

const Calculator: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const [user, setUser] = useState<IUser>(emptyUser);
  const [filtered, setFiltered] = useState<string[]>();
  const [cpfOrCnpj, setCpfOrCnpj] = useState<string>("");
  const [leadPlans, setLeadPlans] = useState<boolean>(false);
  // const [contactHref, setContactHref] = useState<string>("");
  const [production, setProduction] = useState<boolean>(true);
  const [buyCredits, setBuyCredits] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [cpfOrCnpjError, setCpfOrCnpjError] = useState<string[]>();
  const [removeProduction, setRemoveProduction] = useState<number>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [airConditioningType, setAirConditioningType] = useState<number>(0);
  const [excludeProduction, setExcludeProduction] = useState<boolean>(false);
  const [calculatorResult, setCalculatorResult] = useState<
    ICalculatorResult | undefined
  >();
  const [calculatorForm, setCalculatorForm] =
    useState<ICalculatorForm>(emptyCalculatorForm);
  const [productionErrors, setProductionErrors] =
    useState<ProductionErrors[]>();
  const [productionData, setProductionData] = useState<
    IProduction[] | undefined
  >([emptyProduction]);

  const questionsTop = useRef<HTMLDivElement>(null);

  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onChangeHandler = (
    key: keyof ICalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (
        key === "fuelUnit" ||
        key === "transportationUnit" ||
        key === "publicTransportationUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "cookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (key === "airConditioningUnit") {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      if (key === "fireExtinguisherUnit") {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onChangeProductionHandler = (
    key: keyof IProduction,
    index: number,
    value: string | number
  ) => {
    setProductionData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currentPickup = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            return {
              ...currentPickup,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);
      const currProdErrors = production
        ? validateProduction(productionData)
        : [];

      const checkProdError = currProdErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      const currErrorCpfOrCnpj =
        cpfOrCnpj.length !== 11 && cpfOrCnpj.length !== 14
          ? ["Verifique o preenchimento do CPF ou CNPJ"]
          : setCpfOrCnpjError(undefined);

      if (
        (currErrors && Object.keys(currErrors).length) ||
        currErrorCpfOrCnpj ||
        checkProdError > 0
      ) {
        if (currErrors && Object.keys(currErrors).length) setErrors(currErrors);

        if (Object.keys(currErrors).length === 0 && Object.keys(errors).length)
          setErrors({});

        if (currErrorCpfOrCnpj) setCpfOrCnpjError(currErrorCpfOrCnpj);

        if (checkProdError > 0) {
          setProductionErrors(currProdErrors);
          setQuestionNumber(5);
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      setErrors({});
      setProductionErrors(undefined);

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        user: userId || "",
        location: "calculator-ambiensys",
        eventLabel: "calculate-footprint",
      });

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const labelCpfOrCnpj = cpfOrCnpj.length === 11 ? "cpf" : "cnpj";

      const result = await CalculatorService.sendInformation({
        ...calculatorForm,
        userId: userId || "",
        cpfOrCnpj: labelCpfOrCnpj,
        contactMail: calculatorForm.contactMail.toLowerCase().trim(),
        fuelUnit:
          calculatorForm.fuelExpenses && !calculatorForm.fuelUnit
            ? "R$"
            : calculatorForm.fuelUnit,
        energyUnit:
          calculatorForm.energyExpenses && !calculatorForm.energyUnit
            ? "KWh"
            : calculatorForm.energyUnit,
        cookingGasUnit:
          calculatorForm.gasExpenses && !calculatorForm.cookingGasUnit
            ? "Kg"
            : calculatorForm.cookingGasUnit,
        airConditioningUnit:
          calculatorForm.airConditioningRefills &&
          !calculatorForm.airConditioningUnit
            ? "Kg"
            : calculatorForm.airConditioningUnit,
        transportationUnit:
          calculatorForm.transportationExpenses &&
          !calculatorForm.transportationUnit
            ? "R$"
            : calculatorForm.transportationUnit,
        fireExtinguisherUnit:
          calculatorForm.fireExtinguisherRefills &&
          !calculatorForm.fireExtinguisherUnit
            ? "Kg"
            : calculatorForm.fireExtinguisherUnit,
        publicTransportationUnit:
          calculatorForm.publicTransportationExpenses &&
          !calculatorForm.publicTransportationUnit
            ? "R$"
            : calculatorForm.publicTransportationUnit,
        airConditioningType: airConditioningTypes[airConditioningType],
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        production:
          production && productionData && productionData.length > 0
            ? productionData.map((item) => ({
                ...item,
                unit: item.production === "Têxtil" ? "m²" : "mL",
              }))
            : undefined,
      });

      setCalculatorResult(result);

      setUser((curr) => ({
        ...curr,
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        mail: calculatorForm.contactMail,
        cpfOrCnpj: labelCpfOrCnpj,
        company: calculatorForm.companyName,
        contactName: calculatorForm.contactName,
        companyCity: calculatorForm.companyCity,
        companyState: calculatorForm.companyState,
        contactPhone: calculatorForm.contactPhone,
      }));

      localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "admin");
      localStorage.setItem(LOCAL_STORAGE_CALCULATION_ID_KEY, result.id);

      // const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      // const text = `
      // Olá! Gostaria de saber mais sobre planos e recorrência das emissões de carbono da minha empresa. Aqui estão os meus dados:
      // * Nome - ${calculatorForm.contactName}
      // * Empresa - ${calculatorForm.companyName}
      // * Emissões - ${result.totalEmissions
      //   .toFixed(2)
      //   .replace(".", ",")} toneladas de CO2e
      // `;

      // const encodedText = encodeURIComponent(text);

      // setContactHref(url + "?text=" + encodedText);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyCalculatorForm);
    setProductionData([emptyProduction]);
    setProductionErrors(undefined);
    setErrors({});
    setCpfOrCnpj("");
    // setContactHref("");
    setProduction(true);
    setQuestionNumber(1);
    setCpfOrCnpjError(undefined);
    setLeadPlans(false);

    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      eventLabel: "new-consult",
      location: "calculator-ambiensys",
    });
  };

  const onContactClickHandler = async () => {
    try {
      showLoading();

      const userId = getCookieFromUser();

      await Notifications.sendEmail({
        subject: "Lead IBIOMA - Planos e Recorrência",
        templateId: "plans-lead-ambiensys",
        to: [CONTACT_AMBIENSYS],
        variables: {
          name: calculatorForm.companyName,
          email: calculatorForm.contactMail,
          phone: calculatorForm.contactPhone,
          calculator: "Administrativa",
          totalEmissions:
            calculatorResult?.totalEmissions.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "123,45",
          year: new Date().getFullYear().toString(),
        },
      });

      newSuccess(
        "Sua demonstração de interesse foi enviada para nossa equipe, que entrará em contato em breve com você!"
      );

      setLeadPlans(true);

      await Analytics.sendClick({
        user: userId || "",
        location: "calculator-ambiensys",
        eventLabel: "get-in-touch-plans",
        meta: { section: "calculator", method: "whatsapp" },
      });
    } catch (error) {
      newError("Houve um erro ao enviar a mensagem para nossa equipe");
    } finally {
      hideLoading();
    }
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onAddProduction = () => {
    if (!productionData) {
      setProductionData([emptyProduction]);

      return;
    }

    setProductionData((curr) => [...(curr || []), emptyProduction]);
  };

  const onExcludeProduction = (index: number) => {
    if (productionData && productionData.length === 1) {
      newError(
        "É necessária, ao menos, 1 produção. Se não houver produção, selecione 'Não' acima."
      );

      return;
    }

    setRemoveProduction(index);
    setExcludeProduction(true);
  };

  const onRemoveProduction = (index: number) => {
    setProductionData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError(
          "É necessária, ao menos, 1 produção. Se não houver produção, selecione 'Não' acima."
        );

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeProduction(false);
  };

  const airConditioning = Object.values(airConditioningTypesObj);

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  const booleanOptions = ["Sim", "Não"];

  return (
    <S.Container result={!!calculatorResult}>
      {buyCredits ? (
        <BuyCredits
          user={user}
          setUser={(val) => setUser(val)}
          calculatorResult={calculatorResult}
          onCancelBuy={() => setBuyCredits(false)}
          credits={Math.ceil(calculatorResult?.totalEmissions || 1)}
        />
      ) : (
        <>
          <S.TitleAndProgress ref={questionsTop}>
            <S.TitleAndDescription>
              <H1 color={textColor} fontWeight="bold">
                Calculadora
              </H1>

              {!calculatorResult && (
                <H5 color="#010101">
                  Detalhe sua operação respondendo às perguntas abaixo e
                  descubra sua pegada mensal de carbono
                </H5>
              )}
            </S.TitleAndDescription>

            {!calculatorResult && (
              <ProgressBar
                currentQuestion={questionNumber}
                numberOfQuestions={6}
              />
            )}
          </S.TitleAndProgress>

          <S.Content resultBox={!!calculatorResult}>
            {calculatorResult && (
              <S.ChartResult>
                <H2 color={textColor} fontWeight="bold">
                  A pegada mensal de carbono de sua empresa é:
                </H2>

                <H2 color={textColor}>
                  {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
                  toneladas de CO<sub>2</sub>e
                </H2>

                <H3 color={textColor}>
                  Clique em Compensar minhas emissões, para escolher o projeto
                  gerador de créditos de carbono, que mais combina com a sua
                  empresa, e finalize a aquisição. Caso queira saber mais sobre
                  planos e recorrência de neutralizações de suas emissões,
                  clique no botão Quero saber mais sobre planos e recorrência e
                  fale com nosso time!
                </H3>

                <S.ResultButtons>
                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    borderColor={primaryColor}
                    textColor={backgroundColor}
                    backgroundColor={primaryColor}
                    onClick={() => setBuyCredits(true)}
                  >
                    Compensar minhas emissões
                  </Button>

                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    disabled={leadPlans}
                    textColor={primaryColor}
                    borderColor={primaryColor}
                    backgroundColor={backgroundColor}
                    onClick={() => onContactClickHandler()}
                  >
                    {leadPlans
                      ? "Mensagem enviada ao time!"
                      : "Quero saber mais sobre planos e recorrência"}
                  </Button>
                </S.ResultButtons>
              </S.ChartResult>
            )}

            <S.CalculatorBox resultBox={!!calculatorResult}>
              {calculatorResult ? (
                <>
                  <S.Charts>
                    <ChartAndIndicators result={calculatorResult} />
                  </S.Charts>

                  <S.Buttons>
                    <Button
                      variant="solid"
                      fontWeight="bold"
                      borderColor={primaryColor}
                      textColor={backgroundColor}
                      backgroundColor={primaryColor}
                      onClick={() => setBuyCredits(true)}
                    >
                      Compensar emissões
                    </Button>

                    <Button
                      variant="solid"
                      fontWeight="bold"
                      textColor={primaryColor}
                      borderColor={primaryColor}
                      backgroundColor={backgroundColor}
                      onClick={() => onNewConsultHandler()}
                    >
                      Nova consulta
                    </Button>
                  </S.Buttons>
                </>
              ) : (
                <Questions
                  errors={errors}
                  search={search}
                  production={production}
                  question={questionNumber}
                  filteredOptions={filtered}
                  cpfOrCnpj={cpfOrCnpj || ""}
                  formFields={calculatorForm}
                  productionData={productionData}
                  cpfOrCnpjError={cpfOrCnpjError}
                  booleanOptions={booleanOptions}
                  productionErrors={productionErrors}
                  onAheadClick={() => onAheadClick()}
                  airConditioningTypes={airConditioning}
                  transportOptions={transportUnitsOptions}
                  onAddProduction={() => onAddProduction()}
                  setCpfOrCnpj={(val) => setCpfOrCnpj(val)}
                  airConditioningType={airConditioningType}
                  onPreviousClick={() => onPreviousClick()}
                  onSearch={(val, opt) => onSearch(val, opt)}
                  onClearSearch={() => setFiltered(undefined)}
                  onCalculateClick={() => onCalculateHandler()}
                  setProduction={() => setProduction((curr) => !curr)}
                  onRemoveProduction={(val) => onExcludeProduction(val)}
                  onChangeForm={(key, val) => onChangeHandler(key, val)}
                  setAirConditioningType={(val) => setAirConditioningType(val)}
                  onChangeProductionForm={(key, index, val) =>
                    onChangeProductionHandler(key, index, val)
                  }
                />
              )}
            </S.CalculatorBox>
          </S.Content>

          {excludeProduction && (
            <ExcludeModal
              isOpen={excludeProduction}
              onClose={() => setExcludeProduction(false)}
              onExclude={() => onRemoveProduction(removeProduction || 0)}
            />
          )}
        </>
      )}

      <S.Disclamer>
        <P color="#00000060">
          Disclamer: O cálculo da pegada de carbono em nossa calculadora virtual
          é realizado com base em informações precisas, transparentes e
          atualizadas sobre ações e iniciativas, fornecidas unilateralmente pelo
          cliente. Após o fornecimento das informações de maneira unilateral, o
          selo IBIOMA será emitido e devidamente fornecido ao cliente. Ou O
          cálculo da pegada de carbono em nossa calculadora virtual é realizado
          com base em informações precisas, transparentes e atualizadas sobre
          ações e iniciativas, fornecidas unilateralmente pelo cliente. A equipe
          responsável pela Calculadora IBIOMA não se responsabiliza por
          discrepâncias causadas por dados incorretos ou desatualizados.
        </P>
      </S.Disclamer>
    </S.Container>
  );
};

export default Calculator;
