import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PixIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6914 14.2256C13.3675 14.2267 13.0466 14.1665 12.7472 14.0483C12.4478 13.9302 12.1759 13.7565 11.9471 13.5373L9.42755 11.1269C9.33844 11.0455 9.21988 11 9.09658 11C8.97327 11 8.85472 11.0455 8.7656 11.1269L6.23754 13.5439C6.00891 13.7634 5.73705 13.9375 5.43766 14.056C5.13827 14.1745 4.81729 14.2351 4.49327 14.2343H4L7.19268 17.2866C7.67152 17.7434 8.32035 18 8.99681 18C9.67327 18 10.3221 17.7434 10.8009 17.2866L14 14.2276L13.6914 14.2256Z"
        fill={color || primaryColor}
      />
      <path
        d="M4.49406 3.76282C4.81827 3.76199 5.13943 3.82255 5.43898 3.94099C5.73853 4.05943 6.01053 4.2334 6.23926 4.45286L8.76867 6.86927C8.85659 6.95299 8.97568 7 9.09982 7C9.22397 7 9.34305 6.95299 9.43097 6.86927L11.9513 4.46201C12.1799 4.24228 12.4518 4.06809 12.7514 3.94955C13.051 3.83101 13.3723 3.77048 13.6965 3.77146H14L10.7992 0.714328C10.5622 0.487864 10.2808 0.308217 9.97109 0.185651C9.66137 0.0630848 9.3294 0 8.99414 0C8.65889 0 8.32692 0.0630848 8.0172 0.185651C7.70748 0.308217 7.42607 0.487864 7.18905 0.714328L4 3.76282H4.49406Z"
        fill={color || primaryColor}
      />
      <path
        d="M17.1989 7.07359L15.127 5.00909C15.0803 5.02812 15.0304 5.03815 14.9799 5.03864H14.0378C13.5475 5.03986 13.0776 5.23387 12.7301 5.57849L10.0304 8.26694C9.78724 8.50877 9.45774 8.6446 9.1142 8.6446C8.77067 8.6446 8.44117 8.50877 8.19804 8.26694L5.48803 5.56826C5.14065 5.22344 4.6707 5.02922 4.18036 5.02784H3.02382C2.97616 5.02678 2.92906 5.01735 2.88467 5L0.799702 7.07359C0.287603 7.58496 0 8.27787 0 9.00028C0 9.7227 0.287603 10.4156 0.799702 10.927L2.88011 13C2.9244 12.9823 2.97156 12.9729 3.01926 12.9722H4.18036C4.67068 12.9707 5.14062 12.7765 5.48803 12.4317L8.19747 9.73192C8.68734 9.24435 9.54106 9.24435 10.0304 9.73192L12.7301 12.4215C13.0776 12.7661 13.5475 12.9601 14.0378 12.9614H14.9799C15.0304 12.9617 15.0803 12.9717 15.127 12.9909L17.1989 10.9264C17.4529 10.6734 17.6543 10.3731 17.7918 10.0425C17.9293 9.71189 18 9.35755 18 8.99972C18 8.64188 17.9293 8.28754 17.7918 7.95696C17.6543 7.62637 17.4529 7.32601 17.1989 7.07302"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PixIcon;
