import styled, { css } from "styled-components";

import Button from "../../../../components/atoms/Button";
import H4 from "../../../../components/atoms/Typography/H4";

export const CompleteBuy = styled.div`
  top: 20vh;
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 820px) {
    padding: 8px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;

  @media (max-width: 820px) {
    margin-top: 20px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

export const ContentList = styled.div`
  gap: 40px;
  display: flex;
  margin-top: 16px;
  padding: 16px 0 60px;
  flex-direction: column;
  border-bottom: 1px solid #00000080;

  h3 {
    padding-left: 20px;
  }

  @media (max-width: 640px) {
    gap: 20px;
  }
`;

type TitleCustomProps = {
  width: string;
};

export const TitleCustom = styled(H4)<TitleCustomProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    sub {
      font-size: 1.2rem;
      font-weight: bold;
    }

    @media (max-width: 820px) {
      padding: 0;
    }

    @media (max-width: 640px) {
      font-size: 0.8rem;

      sub {
        font-size: 0.75rem;
      }
    }
  `}
`;

export const Project = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const TotalContainer = styled.div`
  gap: 20px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type WidthProps = {
  width: string;
};

export const TotalValue = styled(H4)<WidthProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    @media (max-width: 820px) {
      padding: 0;
    }

    @media (max-width: 640px) {
      font-size: 0.8rem;
    }
  `}
`;

type BeneficiaryProps = {
  borderColor: string;
};

export const Beneficiary = styled.div<BeneficiaryProps>`
  ${({ borderColor }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 60px 20px;
    flex-direction: column;
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};

    @media (max-width: 820px) {
      padding: 40px 0;
    }
  `}
`;

export const IconAndCTA = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 820px) {
    gap: 16px;
  }
`;

export const IconAndLabel = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Payment = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 20px 40px 20px;

  @media (max-width: 820px) {
    padding: 40px 0;
  }
`;

export const Cards = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 400px;
  flex-direction: column;
`;

export const AddCardButton = styled.div`
  width: 100%;
  display: flex;
  max-width: 400px;
`;

export const CTA = styled.div`
  width: 100%;
  display: flex;
  max-width: 100px;
  align-items: center;
`;

export const BuyButton = styled(Button)`
  width: 100%;
  align-self: end;
  max-width: 200px;
  margin-top: 80px;
`;

type TermsProps = {
  textColor: string;
};

export const TermsAndConditions = styled.div<TermsProps>`
  ${({ textColor }) => css`
    display: flex;
    margin-top: 40px;

    a {
      color: ${textColor};
      text-decoration: underline;
      text-decoration-color: ${textColor};
    }
  `}
`;
