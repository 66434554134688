import { useRef, useState } from "react";

import * as S from "./styles";
import {
  Analytics,
  Notifications,
  CalculatorService,
} from "../../../../services";
import {
  vehiclesFuel,
  transportUnitsObj,
  airConditioningTypes,
  airConditioningTypesObj,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
  CONTACT_AMBIENSYS,
} from "../../../../constants";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../constants/colors";
import {
  ILogistic,
  ILogisticRoutes,
  ILogisticCalculatorForm,
} from "../../../../types/logistic";
import BuyCredits from "../../../BuyCredits";
import Questions from "./components/Questions";
import { Loading, Snackbar } from "../../../../hooks";
import { emptyUser } from "../../../../utils/user";
import Button from "../../../../components/atoms/Button";
import { maskCPFOrCNPJ } from "../../../../utils/numbers";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H5 from "../../../../components/atoms/Typography/H5";
import { ICalculatorResult, IUser } from "../../../../types";
import { getCookieFromUser } from "../../../../utils/cookies";
import ProgressBar from "../../../../components/molecules/ProgressBar";
import ExcludeModal from "../../../../components/molecules/ExcludeModal";
import { emptyLogistcCalculatorForm, emptyLogistic } from "../../../../utils";
import ChartAndIndicators from "../../../../components/organisms/ChartAndIndicators";

// import calcResultMock from "../../../../mocks/calculatorResult.json";

const validateForm = (
  data: ILogisticCalculatorForm
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.companyName || data.companyName.length === 0)
    errors.companyName = [
      ...(errors.companyName || []),
      "Nome da empresa é obrigatório",
    ];

  if (!data.companyState)
    errors.companyState = [
      ...(errors.companyState || []),
      "Estado da empresa é obrigatório",
    ];

  if (!data.companyCity)
    errors.companyCity = [
      ...(errors.companyCity || []),
      "Cidade da empresa é obrigatório",
    ];

  if (!data.contactName)
    errors.contactName = [
      ...(errors.contactName || []),
      "Nome para contato é obrigatório",
    ];

  if (!data.contactMail)
    errors.contactMail = [
      ...(errors.contactMail || []),
      "E-mail para contato é obrigatório",
    ];

  if (
    data.contactMail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.contactMail)
  )
    errors.contactMail = [...(errors.contactMail || []), "Verifique o email"];

  if (!data.contactPhone)
    errors.contactPhone = [
      ...(errors.contactPhone || []),
      "Telefone para contato é obrigatório",
    ];

  return errors;
};

export interface LogisticErrors {
  load?: string | undefined;
  fuelType: string | undefined;
  vehicleType: string | undefined;
  fuelExpenses: string | undefined;
}

const validateLogistic = (data?: ILogistic[]): LogisticErrors[] => {
  const errors = (() => {
    if (data && data.length > 0) {
      const logisticErrors = data.map((item) => {
        const fuel =
          item.fuelExpenses === "0,00" || !item.fuelExpenses
            ? "Valor deve ser maior que zero"
            : undefined;

        // const load =
        //   item.load === "0,00" || !item.load
        //     ? "Valor deve ser maior que zero"
        //     : undefined;

        const vehicleType =
          item.vehicleType === "" ? "Selecione um veículo" : undefined;

        const fuelType =
          item.fuelType === "" ? "Selecione um combustível" : undefined;

        return {
          // load: load,
          fuelType: fuelType,
          fuelExpenses: fuel,
          vehicleType: vehicleType,
        };
      });

      return logisticErrors;
    }

    return [
      {
        fuelType: "Selecione um veículo",
        vehicleType: "Selecione um veículo",
        // load: "Valor deve ser maior que zero",
        fuelExpenses: "Valor deve ser maior que zero",
      },
    ];
  })();

  return errors;
};

const Calculator: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const [user, setUser] = useState<IUser>(emptyUser);
  const [office, setOffice] = useState<boolean>(true);
  const [filtered, setFiltered] = useState<string[]>();
  const [pickups, setPickups] = useState<boolean>(true);
  const [cpfOrCnpj, setCpfOrCnpj] = useState<string>("");
  const [warehouse, setWarehouse] = useState<boolean>(true);
  // const [contactHref, setContactHref] = useState<string>("");
  const [removePickup, setRemovePickup] = useState<number>();
  const [documentName, setDocumentName] = useState<string>();
  const [leadPlans, setLeadPlans] = useState<boolean>(false);
  const [buyCredits, setBuyCredits] = useState<boolean>(false);
  const [removeDelivery, setRemoveDelivery] = useState<number>();
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [cpfOrCnpjError, setCpfOrCnpjError] = useState<string[]>();
  const [excludePickup, setExcludePickup] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [excludeDelivery, setExcludeDelivery] = useState<boolean>(false);
  const [pickupsErrors, setPickupsErrors] = useState<LogisticErrors[]>();
  const [airConditioningType, setAirConditioningType] = useState<number>(0);
  const [deliveriesErrors, setDeliveriesErrors] = useState<LogisticErrors[]>();
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();
  const [pickupsData, setPickupsData] = useState<ILogistic[] | undefined>([
    emptyLogistic,
  ]);
  const [calculatorForm, setCalculatorForm] = useState<ILogisticCalculatorForm>(
    emptyLogistcCalculatorForm
  );
  const [deliveriesData, setDeliveriesData] = useState<ILogistic[] | undefined>(
    [emptyLogistic]
  );

  const questionsTop = useRef<HTMLDivElement>(null);

  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onChangeHandler = (
    key: keyof ILogisticCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (
        key === "officeTransportationUnit" ||
        key === "warehouseTransportationUnit" ||
        key === "officePublicTransportationUnit" ||
        key === "warehousePublicTransportationUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "officeCookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "officeEnergyUnit" || key === "warehouseEnergyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (
        key === "airConditioningUnit" ||
        key === "warehouseRefrigeratorUnit"
      ) {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      if (key === "routesMethod") {
        if (value === "manually") {
          setDocumentName(undefined);

          return {
            ...curr,
            [key]: value,
            routes: undefined,
          };
        }

        if (value === "upload") {
          setPickups(false);
          setPickupsErrors(undefined);
          setDeliveriesErrors(undefined);
          setPickupsData([emptyLogistic]);
          setDeliveriesData([emptyLogistic]);

          return {
            ...curr,
            [key]: value,
            routes: undefined,
          };
        }
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onAddRoutes = (routesData: ILogisticRoutes[], document: string) => {
    setCalculatorForm((curr) => ({
      ...curr,
      routes: routesData,
    }));

    setDocumentName(document);
  };

  const onChangePickupHandler = (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => {
    setPickupsData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currentPickup = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            if (key === "expensesUnit") {
              return {
                ...currentPickup,
                [key]: Object.values(transportUnitsObj)[+value],
              };
            }

            if (key === "fuelType") {
              return {
                ...currentPickup,
                [key]:
                  Object.keys(vehiclesFuel)[
                    Object.values(vehiclesFuel).indexOf(value.toString())
                  ],
              };
            }

            return {
              ...currentPickup,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onChangeDeliveryHandler = (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => {
    setDeliveriesData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currentDelivery = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            if (key === "expensesUnit") {
              return {
                ...currentDelivery,
                [key]: Object.values(transportUnitsObj)[+value],
              };
            }

            if (key === "fuelType") {
              return {
                ...currentDelivery,
                [key]:
                  Object.keys(vehiclesFuel)[
                    Object.values(vehiclesFuel).indexOf(value.toString())
                  ],
              };
            }

            return {
              ...currentDelivery,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyLogistcCalculatorForm);
    setErrors({});
    setOffice(true);
    setCpfOrCnpj("");
    setPickups(true);
    setWarehouse(true);
    // setContactHref("");
    setQuestionNumber(1);
    setPickupsErrors(undefined);
    setCpfOrCnpjError(undefined);
    setDeliveriesErrors(undefined);
    setPickupsData([emptyLogistic]);
    setDeliveriesData([emptyLogistic]);
    setLeadPlans(false);

    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      eventLabel: "new-consult",
      location: "logistic-calculator-ambiensys",
    });
  };

  const onContactClickHandler = async () => {
    try {
      showLoading();

      const userId = getCookieFromUser();

      await Notifications.sendEmail({
        subject: "Lead IBIOMA - Planos e Recorrência",
        templateId: "plans-lead-ambiensys",
        to: [CONTACT_AMBIENSYS],
        variables: {
          name: calculatorForm.companyName,
          email: calculatorForm.contactMail,
          phone: calculatorForm.contactPhone,
          calculator: "Logística",
          totalEmissions:
            calculatorResult?.totalEmissions.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          year: new Date().getFullYear().toString(),
        },
      });

      newSuccess(
        "Sua demonstração de interesse foi enviada para nossa equipe, que entrará em contato em breve com você!"
      );

      setLeadPlans(true);

      await Analytics.sendClick({
        user: userId || "",
        eventLabel: "get-in-touch-plans",
        location: "logistic-calculator-ambiensys",
        meta: { section: "calculator", method: "whatsapp" },
      });
    } catch (error) {
      newError("Houve um erro ao enviar a mensagem para nossa equipe");
    } finally {
      hideLoading();
    }
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onAddPickup = () => {
    if (!pickupsData) {
      setPickupsData([emptyLogistic]);

      return;
    }

    setPickupsData((curr) => [...(curr || []), emptyLogistic]);
  };

  const onExcludePickup = (index: number) => {
    if (pickupsData && pickupsData.length === 1) {
      newError(
        "É necessária, ao menos, 1 coleta. Caso não queira adicionar coletas, selecione 'Não' acima."
      );

      return;
    }

    setRemovePickup(index);
    setExcludePickup(true);
  };

  const onExcludeDelivery = (index: number) => {
    if (deliveriesData && deliveriesData.length === 1) {
      newError("É necessária, ao menos, 1 entrega.");

      return;
    }

    setRemoveDelivery(index);
    setExcludeDelivery(true);
  };

  const onRemovePickup = (index: number) => {
    setPickupsData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError(
          "É necessária, ao menos, 1 coleta. Caso não queira adicionar coletas, selecione 'Não' acima."
        );

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludePickup(false);
  };

  const onAddDelivery = () => {
    if (!deliveriesData) {
      setDeliveriesData([emptyLogistic]);

      return;
    }

    setDeliveriesData((curr) => [...(curr || []), emptyLogistic]);
  };

  const onRemoveDelivery = (index: number) => {
    setDeliveriesData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError("É necessária, ao menos, 1 entrega.");

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeDelivery(false);
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);
      const currDelivErrors =
        calculatorForm.routesMethod === "manually"
          ? validateLogistic(deliveriesData)
          : [];
      const currPickErrors =
        calculatorForm.routesMethod === "manually" && pickups
          ? validateLogistic(pickupsData)
          : [];

      const checkPickError = currPickErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      const checkDelivError = currDelivErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      const currErrorCpfOrCnpj =
        cpfOrCnpj.length !== 11 && cpfOrCnpj.length !== 14
          ? ["Verifique o preenchimento do CPF ou CNPJ"]
          : setCpfOrCnpjError(undefined);

      if (
        (currErrors && Object.keys(currErrors).length) ||
        currErrorCpfOrCnpj ||
        checkDelivError > 0 ||
        checkPickError > 0
      ) {
        if (currErrors && Object.keys(currErrors).length) setErrors(currErrors);
        if (Object.keys(currErrors).length === 0 && Object.keys(errors).length)
          setErrors({});
        if (checkDelivError > 0) {
          setDeliveriesErrors(currDelivErrors);
          setQuestionNumber(1);
        }
        if (checkPickError > 0) {
          setPickupsErrors(currPickErrors);
          setQuestionNumber(1);
        }
        if (currErrorCpfOrCnpj) setCpfOrCnpjError(currErrorCpfOrCnpj);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        user: userId || "",
        eventLabel: "calculate-footprint",
        location: "logistic-calculator-ambiensys",
      });

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const labelCpfOrCnpj = cpfOrCnpj.length === 11 ? "cpf" : "cnpj";

      const result = await CalculatorService.sendLogisticInformation({
        ...calculatorForm,
        userId: userId || "",
        cpfOrCnpj: labelCpfOrCnpj,
        contactMail: calculatorForm.contactMail.toLowerCase().trim(),
        officeEnergyUnit:
          calculatorForm.officeEnergyExpenses &&
          !calculatorForm.officeEnergyUnit
            ? "KWh"
            : calculatorForm.officeEnergyUnit,
        airConditioningUnit:
          calculatorForm.airConditioningRefills &&
          !calculatorForm.airConditioningUnit
            ? "Kg"
            : calculatorForm.airConditioningUnit,
        warehouseEnergyUnit:
          calculatorForm.warehouseEnergyExpenses &&
          !calculatorForm.warehouseEnergyUnit
            ? "KWh"
            : calculatorForm.warehouseEnergyUnit,
        officeCookingGasUnit:
          calculatorForm.officeCookingGasExpenses &&
          !calculatorForm.officeCookingGasUnit
            ? "Kg"
            : calculatorForm.officeCookingGasUnit,
        officeTransportationUnit:
          calculatorForm.officeTransportationExpenses &&
          !calculatorForm.officePublicTransportationUnit
            ? "R$"
            : calculatorForm.officePublicTransportationUnit,
        warehouseRefrigeratorUnit:
          calculatorForm.warehouseRefrigeratorRefills &&
          !calculatorForm.warehouseRefrigeratorUnit
            ? "Kg"
            : calculatorForm.warehouseRefrigeratorUnit,
        warehouseTransportationUnit:
          calculatorForm.warehouseTransportationExpenses &&
          !calculatorForm.warehouseTransportationUnit
            ? "R$"
            : calculatorForm.warehouseTransportationUnit,
        officePublicTransportationUnit:
          calculatorForm.officePublicTransportationExpenses &&
          !calculatorForm.officePublicTransportationUnit
            ? "R$"
            : calculatorForm.officePublicTransportationUnit,
        warehousePublicTransportationUnit:
          calculatorForm.warehousePublicTransportationExpenses &&
          !calculatorForm.warehousePublicTransportationUnit
            ? "R$"
            : calculatorForm.warehousePublicTransportationUnit,
        deliveriesData:
          calculatorForm.routesMethod === "manually"
            ? deliveriesData
            : undefined,
        pickupsData:
          calculatorForm.routesMethod === "manually" && pickups
            ? pickupsData
            : undefined,
        airConditioningType: airConditioningTypes[airConditioningType],
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
      });

      setDocumentName(undefined);
      setCalculatorResult(result);

      setUser((curr) => ({
        ...curr,
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        mail: calculatorForm.contactMail,
        cpfOrCnpj: labelCpfOrCnpj,
        company: calculatorForm.companyName,
        contactName: calculatorForm.contactName,
        companyCity: calculatorForm.companyCity,
        companyState: calculatorForm.companyState,
        contactPhone: calculatorForm.contactPhone,
      }));

      localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "logistic");
      localStorage.setItem(LOCAL_STORAGE_CALCULATION_ID_KEY, result.id);

      // const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      // const text = `
      // Olá! Gostaria de saber mais sobre planos e recorrência das emissões de carbono da minha empresa. Aqui estão os meus dados:
      // * Nome - ${calculatorForm.contactName}
      // * Empresa - ${calculatorForm.companyName}
      // * Emissões - ${result.totalEmissions
      //   .toFixed(2)
      //   .replace(".", ",")} toneladas de CO2e
      // `;

      // const encodedText = encodeURIComponent(text);

      // setContactHref(url + "?text=" + encodedText);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const airConditioning = Object.values(airConditioningTypesObj);

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  const booleanOptions = ["Sim", "Não"];

  return (
    <S.Container result={!!calculatorResult}>
      {buyCredits ? (
        <BuyCredits
          user={user}
          setUser={(val) => setUser(val)}
          onCancelBuy={() => setBuyCredits(false)}
          credits={Math.ceil(calculatorResult?.totalEmissions || 1)}
        />
      ) : (
        <>
          <S.TitleAndProgress ref={questionsTop}>
            <S.TitleAndDescription>
              <H1 color={textColor} fontWeight="bold">
                Calculadora
              </H1>

              {!calculatorResult && (
                <H5 color="#010101">
                  Detalhe sua operação respondendo às perguntas abaixo e
                  descubra sua pegada mensal de carbono
                </H5>
              )}
            </S.TitleAndDescription>

            {!calculatorResult && (
              <ProgressBar
                currentQuestion={questionNumber}
                numberOfQuestions={6}
              />
            )}
          </S.TitleAndProgress>

          <S.Content resultBox={!!calculatorResult}>
            {calculatorResult && (
              <S.ChartResult>
                <H2 color={textColor} fontWeight="bold">
                  A pegada mensal de carbono de sua empresa é:
                </H2>

                <H2 color={textColor}>
                  {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
                  toneladas de CO<sub>2</sub>e
                </H2>

                <H3 color={textColor}>
                  Clique em Compensar minhas emissões, para escolher o projeto
                  gerador de créditos de carbono, que mais combina com a sua
                  empresa, e finalize a aquisição. Caso queira saber mais sobre
                  planos e recorrência de neutralizações de suas emissões,
                  clique no botão Quero saber mais sobre planos e recorrência e
                  fale com nosso time!
                </H3>

                <S.ResultButtons>
                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    borderColor={primaryColor}
                    textColor={backgroundColor}
                    backgroundColor={primaryColor}
                    onClick={() => setBuyCredits(true)}
                  >
                    Compensar minhas emissões
                  </Button>

                  <Button
                    variant="solid"
                    maxWidth="300px"
                    fontWeight="bold"
                    disabled={leadPlans}
                    textColor={primaryColor}
                    borderColor={primaryColor}
                    backgroundColor={backgroundColor}
                    onClick={() => onContactClickHandler()}
                  >
                    {leadPlans
                      ? "Mensagem enviada ao time!"
                      : "Quero saber mais sobre planos e recorrência"}
                  </Button>
                </S.ResultButtons>
              </S.ChartResult>
            )}

            <S.CalculatorBox resultBox={!!calculatorResult}>
              {calculatorResult ? (
                <>
                  <S.Charts>
                    <S.ChartBox>
                      <ChartAndIndicators result={calculatorResult} />
                    </S.ChartBox>
                  </S.Charts>

                  <S.Buttons>
                    <Button
                      variant="solid"
                      fontWeight="bold"
                      borderColor={primaryColor}
                      textColor={backgroundColor}
                      backgroundColor={primaryColor}
                      onClick={() => setBuyCredits(true)}
                    >
                      Compensar emissões
                    </Button>

                    <Button
                      variant="solid"
                      fontWeight="bold"
                      textColor={primaryColor}
                      borderColor={primaryColor}
                      backgroundColor={backgroundColor}
                      onClick={() => onNewConsultHandler()}
                    >
                      Nova consulta
                    </Button>
                  </S.Buttons>
                </>
              ) : (
                <Questions
                  search={search}
                  office={office}
                  errors={errors}
                  pickups={pickups}
                  warehouse={warehouse}
                  pickupsData={pickupsData}
                  question={questionNumber}
                  filteredOptions={filtered}
                  documentName={documentName}
                  cpfOrCnpj={cpfOrCnpj || ""}
                  formFields={calculatorForm}
                  pickupsErrors={pickupsErrors}
                  deliveriesData={deliveriesData}
                  booleanOptions={booleanOptions}
                  cpfOrCnpjError={cpfOrCnpjError}
                  onAddPickup={() => onAddPickup()}
                  deliveriesErrors={deliveriesErrors}
                  onAheadClick={() => onAheadClick()}
                  onAddDelivery={() => onAddDelivery()}
                  airConditioningTypes={airConditioning}
                  transportOptions={transportUnitsOptions}
                  setCpfOrCnpj={(val) => setCpfOrCnpj(val)}
                  airConditioningType={airConditioningType}
                  onPreviousClick={() => onPreviousClick()}
                  onSearch={(val, opt) => onSearch(val, opt)}
                  onClearSearch={() => setFiltered(undefined)}
                  setOffice={() => setOffice((curr) => !curr)}
                  onCalculateClick={() => onCalculateHandler()}
                  onRemovePickup={(val) => onExcludePickup(val)}
                  setPickups={() => setPickups((curr) => !curr)}
                  onRemoveDelivery={(val) => onExcludeDelivery(val)}
                  setWarehouse={() => setWarehouse((curr) => !curr)}
                  onChangeForm={(key, val) => onChangeHandler(key, val)}
                  onAddRoutes={(doc, docName) => onAddRoutes(doc, docName)}
                  setAirConditioningType={(val) => setAirConditioningType(val)}
                  onChangeDeliveryForm={(key, index, val) =>
                    onChangeDeliveryHandler(key, index, val)
                  }
                  onChangePickupForm={(key, index, val) =>
                    onChangePickupHandler(key, index, val)
                  }
                />
              )}
            </S.CalculatorBox>
          </S.Content>
        </>
      )}

      {excludePickup && (
        <ExcludeModal
          isOpen={excludePickup}
          onClose={() => setExcludePickup(false)}
          onExclude={() => onRemovePickup(removePickup || 0)}
        />
      )}

      {excludeDelivery && (
        <ExcludeModal
          isOpen={excludeDelivery}
          onClose={() => setExcludeDelivery(false)}
          onExclude={() => onRemoveDelivery(removeDelivery || 0)}
        />
      )}

      <S.Disclamer>
        <P color="#00000060">
          Disclamer: O cálculo da pegada de carbono em nossa calculadora virtual
          é realizado com base em informações precisas, transparentes e
          atualizadas sobre ações e iniciativas, fornecidas unilateralmente pelo
          cliente. Após o fornecimento das informações de maneira unilateral, o
          selo IBIOMA será emitido e devidamente fornecido ao cliente. Ou O
          cálculo da pegada de carbono em nossa calculadora virtual é realizado
          com base em informações precisas, transparentes e atualizadas sobre
          ações e iniciativas, fornecidas unilateralmente pelo cliente. A equipe
          responsável pela Calculadora IBIOMA não se responsabiliza por
          discrepâncias causadas por dados incorretos ou desatualizados.
        </P>
      </S.Disclamer>
    </S.Container>
  );
};

export default Calculator;
