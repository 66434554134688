import * as S from "./styles";
import { TBrand } from "../../../types";
import {
  textColor,
  primaryColor,
  tertiaryColor,
} from "../../../constants/colors";
import BrandIcons from "../../atoms/BrandIcons";
import Label from "../../atoms/Typography/Label";
import { maskCreditCard } from "../../../utils/order";

interface IProps {
  name: string;
  brand: TBrand;
  number: string;
  isSelected: boolean;
  onSelect: () => void;
}

const CreditCardsCard: React.FC<IProps> = ({
  name,
  brand,
  number,
  onSelect,
  isSelected,
}) => {
  return (
    <S.Card
      isSelected={isSelected}
      borderColor={primaryColor}
      onClick={() => onSelect()}
      backgroundColor={tertiaryColor}
    >
      <S.Brand>
        <BrandIcons option={brand} />
      </S.Brand>

      <S.Texts>
        <Label color={textColor} fontWeight="bold">
          {maskCreditCard(number)}
        </Label>

        <Label color={textColor} fontWeight="bold">
          {name.toUpperCase()}
        </Label>
      </S.Texts>
    </S.Card>
  );
};

export default CreditCardsCard;
