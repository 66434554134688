import { Navigate, Route, Routes } from "react-router-dom";

// import Home from "../../pages/Home";
import Projects from "../../pages/Projects";
import Calculadora from "../../pages/Calculadora";
import CalculadoraAgro from "../../pages/CalculadoraAgro";
import CalculadoraEventos from "../../pages/CalculadoraEventos";
import RoutesSheetTemplate from "../../pages/RoutesSheetTemplate";
import CalculadoraLogistica from "../../pages/CalculadoraLogistica";

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/projetos" element={<Projects />} />
      <Route path="/calculadora" element={<Calculadora />} />
      <Route path="/calculadora-agro" element={<CalculadoraAgro />} />
      <Route path="/template-rotas" element={<RoutesSheetTemplate />} />
      <Route path="/calculadora-eventos" element={<CalculadoraEventos />} />
      <Route path="/calculadora-logistica" element={<CalculadoraLogistica />} />

      <Route path="*" element={<Navigate to="/calculadora" />} />
    </Routes>
  );
};

export default PublicRoutes;
