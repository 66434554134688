import * as S from "./styles";
import {
  TBilling,
  ICreditCard,
  TBillingStatus,
  ICreditCardHolderInfo,
} from "../../../../types/order";
import { textColor } from "../../../../constants/colors";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import PaymentDetails from "../../../../components/organisms/PaymentDetails";

interface IProps {
  payment: TBilling;
  totalValue: number;
  invoiceUrl?: string;
  orderAsaasId: string;
  biggerTextarea: boolean;
  copyIdentification?: string;
  paymentStatus: TBillingStatus;
  card?: {
    creditCard?: ICreditCard;
    creditCardToken?: string;
    creditCardHolderInfo?: ICreditCardHolderInfo;
  };
}

const BillingInfo: React.FC<IProps> = ({
  card,
  payment,
  totalValue,
  invoiceUrl,
  orderAsaasId,
  paymentStatus,
  biggerTextarea,
  copyIdentification,
}) => {
  return (
    <S.BillingInfo>
      <H1 color={textColor}>Dados do pagamento</H1>

      <S.Content>
        <S.Description>
          <H3 color={textColor}>
            O status do pagamento pode levar alguns minutos para atualizar. Em
            caso de problema ou inconsistência, entre em contato com nosso time
          </H3>
        </S.Description>

        <PaymentDetails
          card={card}
          invoiceUrl={invoiceUrl}
          payment={payment || "PIX"}
          orderAsaasId={orderAsaasId}
          billingValue={totalValue || 0}
          biggerTextarea={biggerTextarea}
          copyIdentification={copyIdentification}
          paymentStatus={paymentStatus || "PENDING"}
        />
      </S.Content>
    </S.BillingInfo>
  );
};

export default BillingInfo;
