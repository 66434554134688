import axios from "axios";

import { IMailNotificationData } from "../../types/notifications";

const { REACT_APP_NOTIFICATIONS_API_URL } = process.env;

const sendEmail = async (data: IMailNotificationData) => {
  await axios.post(`${REACT_APP_NOTIFICATIONS_API_URL}/mail`, data);
};

const notificationsFunctions = {
  sendEmail,
};

export default notificationsFunctions;
