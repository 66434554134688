import { useState } from "react";

import * as S from "./styles";
import AvailableIcons from "../AvailableIcons";
import { CONTACT_PHONE_NUMBER } from "../../../constants";

const WhatsappFloating: React.FC = () => {
  const [showBackground, setShowBackground] = useState<boolean>(false);

  return (
    <S.Icon
      target="_blank"
      rel="noreferrer noopener"
      background={showBackground}
      onMouseEnter={() => setShowBackground(true)}
      onMouseLeave={() => setShowBackground(false)}
      href={`https://wa.me/${CONTACT_PHONE_NUMBER}`}
    >
      <AvailableIcons option="whatsapp" />
    </S.Icon>
  );
};

export default WhatsappFloating;
