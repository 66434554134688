import {
  IProduction,
  IPieChartData,
  ICalculatorForm,
  TEmissionsSources,
} from "../types";
import {
  herdAge,
  herdSex,
  cropsObj,
  soilTexturalObj,
  clayInTheSoilObj,
  farmingAdoptionTime,
} from "../constants";
import {
  TAge,
  TSex,
  ICrop,
  TCrop,
  TClayInTheSoil,
  TSoilTexturalClass,
  IAgroCalculatorForm,
  TFarmingSystemAdoptionTime,
} from "../types/agro";
import { IContactForm, IRequestAccess } from "../types/lead";
import { IEventCalculatorForm, IInputValues } from "../types/event";
import { ILogistic, ILogisticCalculatorForm } from "../types/logistic";
import { IOrderDetails } from "../types/order";

export const emptyContactForm: IContactForm = {
  name: "",
  mail: "",
  phone: "",
  userId: "",
  message: "",
};

export const emptyPlataform: IRequestAccess = {
  name: "",
  mail: "",
  phone: "",
  userId: "",
  company: "",
};

export const emptyCalculatorForm: ICalculatorForm = {
  userId: "",
  employees: "1",
  contactName: "",
  contactMail: "",
  companyName: "",
  companyCity: "",
  contactPhone: "",
  companyState: "",
  cpfOrCnpj: "cnpj",
  companyVertical: "",
  airConditioningType: "none",
};

export const emptyEventCalculatorForm: IEventCalculatorForm = {
  userId: "",
  public: "1",
  eventName: "",
  eventCity: "",
  employees: "1",
  eventState: "",
  contactName: "",
  contactMail: "",
  maxCapacity: "1",
  contactPhone: "",
  cpfOrCnpj: "cnpj",
  eventVertical: "",
  energySource: "city-energy",
};

export const emptyCrop: ICrop = {
  urea: "",
  acreage: "",
};

export const emptyAgroCalculatorForm: IAgroCalculatorForm = {
  userId: "",
  employees: "1",
  contactName: "",
  contactMail: "",
  producerName: "",
  producerCity: "",
  contactPhone: "",
  cpfOrCnpj: "cnpj",
  producerState: "",
};

export const emptyLogistic: ILogistic = {
  fuelType: "",
  vehicleType: "",
  fuelExpenses: "",
  expensesUnit: "R$",
};

export const emptyProduction: IProduction = {
  product: "",
  dimension: "",
};

export const emptyLogistcCalculatorForm: ILogisticCalculatorForm = {
  userId: "",
  companyCity: "",
  contactName: "",
  companyName: "",
  contactMail: "",
  contactPhone: "",
  companyState: "",
  cpfOrCnpj: "cnpj",
  routesMethod: "manually",
};

export const emptyProjectOrder: IOrderDetails[] = [
  {
    label: "",
    image: "",
    pricing: 0,
    quantity: 0,
    storage: "",
    projectId: "",
    projectName: "",
    availableCredits: 0,
    shortDescription: "",
  },
];

export const colorsBarChart = ["#257034", "#a6ba4c", "#84bd47", "#c9ec2d"];

export const chartConfig: {
  [key in TEmissionsSources]: { label: string; color: string };
} = {
  gas: { label: "Gás", color: "#9BD4EA" },
  flights: { label: "Voos", color: "#5F5530" },
  herds: { label: "Pecuária", color: "#6DCE87" },
  fuel: { label: "Combustível", color: "#9F00EC" },
  generator: { label: "Gerador", color: "#5d1113" },
  crops: { label: "Agricultura", color: "#BCCA25" },
  residues: { label: "Resíduos", color: "#FFA266" },
  production: { label: "Produção", color: "#081534" },
  deliveries: { label: "Entregas", color: "#00B69D" },
  transport: { label: "Transporte", color: "#2C96D2" },
  thirdParties: { label: "Terceiros", color: "#FF5C01" },
  energy: { label: "Energia elétrica", color: "#FF9687" },
  preservation: { label: "Preservação", color: "#00FF00" },
  deforestation: { label: "Desmatamento", color: "#ff0000" },
  fugitives: { label: "Emissões fugitivas", color: "#9F90C1" },
};

export const calculatePercentagePie = (data: IPieChartData[]) => {
  const sumValues = data.reduce((acc, curr) => {
    acc += curr.value;

    return acc;
  }, 0);

  const percentageValues = data.map((item) => ({
    id: item.id,
    value: ((item.value / sumValues) * 100).toFixed(1) + "%",
  }));

  return percentageValues;
};

export const calculatePercentage = (value: number, total: number) => {
  const percentage = 100 * (value / total);

  return `${percentage.toFixed(0)}%`;
};

export const emptySupplier: IInputValues = {
  label: "",
  value: "0",
};

export const getCropByValue = (value: string): TCrop => {
  return Object.keys(cropsObj).find(
    (key) => cropsObj[key as TCrop] === value
  ) as TCrop;
};

export const getSexByValue = (value: string): TSex => {
  return Object.keys(herdSex).find(
    (key) => herdSex[key as TSex] === value
  ) as TSex;
};

export const getClayInTheSoilByValue = (value: string): TClayInTheSoil => {
  return Object.keys(clayInTheSoilObj).find(
    (key) => clayInTheSoilObj[key as TClayInTheSoil] === value
  ) as TClayInTheSoil;
};

export const getSoilTextureByValue = (value: string): TSoilTexturalClass => {
  return Object.keys(soilTexturalObj).find(
    (key) => soilTexturalObj[key as TSoilTexturalClass] === value
  ) as TSoilTexturalClass;
};

export const getFarmAdoptionByValue = (
  value: string
): TFarmingSystemAdoptionTime => {
  return Object.keys(farmingAdoptionTime).find(
    (key) => farmingAdoptionTime[key as TFarmingSystemAdoptionTime] === value
  ) as TFarmingSystemAdoptionTime;
};

export const getHerdAgeByValue = (value: string): TAge => {
  return Object.keys(herdAge).find(
    (key) => herdAge[key as TAge] === value
  ) as TAge;
};
